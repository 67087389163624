import React from "react";

// Styles
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmbulance, faDiagnoses } from "@fortawesome/pro-solid-svg-icons";
import {
  TopLabel,
  SmallTitle,
  MediumText,
  LargeText,
  AnchorTag,
  fontWeight,
  fontFamily,
  BodyCopy,
} from "../Typography";
import { blue } from "../Utilities";

// Apollo, GraphQL
import { useQuery, gql } from "@apollo/client";

const GET_FEATURED_ENCOUNTER = gql`
  query getCollection($collectionUrl: String!) {
    getCollection(collectionUrl: $collectionUrl) {
      id
      encounterSet {
        id
        hashId
        title
        description
      }
    }
  }
`;

export default function ClinicalCase(props) {
  const { mixpanel } = props;

  const { loading, error, data } = useQuery(GET_FEATURED_ENCOUNTER, {
    variables: { collectionUrl: "homepage-featured" },
  });
  if (loading) return null;
  if (error) return null;

  const encounters = data.getCollection.encounterSet;

  function handleClick(hashId) {
    mixpanel.track("Homepage", {
      Category: "Encounter",
      EncounterID: hashId,
      Action: "Clicked",
      "Click Action": "Featured Encounter",
    });

    window.location = "https://rounds.medzcool.com/case/" + hashId;
  }

  return (
    <div>
      {encounters.map((encounter) => {
        return (
          <ClinicalCaseContainer
            key={encounter.id}
            onClick={() => handleClick(encounter.hashId)}>
            <FontAwesomeIcon icon={faDiagnoses} size="2x" />
            <div>
              <TopLabel>Featured Case</TopLabel>
              <SmallTitle>{encounter.title}</SmallTitle>
              <BodyCopy>{encounter.description}</BodyCopy>
              <BodyCopy className="answer-prompt">Answer Case</BodyCopy>
            </div>
          </ClinicalCaseContainer>
        );
      })}
    </div>
  );
}

const ClinicalCaseContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 50px;
  align-items: center;
  background: rgb(249, 249, 249);
  padding: 25px;
  border-radius: 5px;
  font-family: ${fontFamily.sansSerif};
  max-width: 900px;
  margin: auto;
  cursor: pointer;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.1);
  .answer-prompt {
    color: ${blue.bright};
    margin: 8px 0 0 0;
  }
  ${SmallTitle} {
    margin-bottom: 4px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 15px;
  }
`;
