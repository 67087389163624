/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_USER = gql`
  query GET_USER {
    user {
      id
      isSuperuser
      username
      email
      profile {
        id
        subscribedApps {
          id
          title
          description
          url
          logo
          priceId
        }
        orders {
          id
        }
        subscribedRounds {
          id
          collectionTitle
          description
          checkoutThumbnail
          collectionUrl
        }
        latestInvoiceId
        subscriptionStatus
        paymentIntentStatus
        stripeCustomerId
        subscriptionId
        priceId
        productId
        stripeCustomerPaymentMethodId
        stripeExpMonth
        stripeExpYear
        stripeLast4
        stripeCardBrand
        custom
      }
    }
  }
`;

export const GET_COLLECTION = gql`
  query getCollection($collectionId: Int, $collectionUrl: String) {
    getCollection(collectionId: $collectionId, collectionUrl: $collectionUrl) {
      id
      collectionUrl
      collectionTitle
      description
      thumbnail
      price
      priceId
      encounterSet {
        id
        hashId
        encounterType
        title
        description
        thumbnail
        dateCreated
        attention
        isPublic
        clinicEncounter
        incomplete
        author {
          id
          username
        }
        tags {
          id
          name
        }
      }
    }
  }
`;
