import React from "react";
import { mixpanelToken } from "../settings";

// Dependencies
import mixpanel from "mixpanel-browser";

// Styles
import styled from "styled-components";
import {
  Logo,
  SmallText,
  AnchorTag,
  fontFamily,
  fontWeight,
} from "../Typography";
import { themeColors } from "../Utilities";

export default function Footer(props) {
  return (
    <FooterContainer>
      <FooterInnerContainer>
        <LogoContainer>
          <div className="logo-text">MEDZCOOL</div>
          <SmallText>
            © <span>{new Date().getFullYear()}.</span> ALL RIGHTS RESERVED
          </SmallText>
          <div className="policies">
            <span>
              <AnchorTag color={`${themeColors.gray}`} href="/terms">
                Terms of Service &middot;{" "}
              </AnchorTag>
            </span>
            <span>
              <AnchorTag color={`${themeColors.gray}`} href="/privacy">
                Privacy Policy &middot;{" "}
              </AnchorTag>
            </span>
            <span>
              <AnchorTag color={`${themeColors.gray}`} href="/refund">
                Refund Policy
              </AnchorTag>
            </span>
          </div>
        </LogoContainer>
        <Menu>
          <Column>
            <div className="footer-section-title">EXPLORE</div>
            <ul>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://medzcool.com">
                  Home
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://youtube.com/medzcool">
                  Videos
                </AnchorTag>
              </li>
            </ul>
          </Column>
          <Column>
            <div className="footer-section-title">PRODUCTS</div>
            <ul>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://medzcool.com/auscultate">
                  Auscultate
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://medzcool.com/clinic">
                  Clinic
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://medzcool.com/cranial">
                  Cranial
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://medzcool.com/kardiograph">
                  Kardiograph
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://rounds.medzcool.com">
                  Rounds
                </AnchorTag>
              </li>
            </ul>
          </Column>
          <Column>
            <div className="footer-section-title">FOLLOW US</div>
            <ul>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://youtube.com/medzcool">
                  YouTube
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://instagram.com/medzcool">
                  Instagram
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://facebook.com/medzcooleducation">
                  Facebook
                </AnchorTag>
              </li>
              <li>
                <AnchorTag
                  color={`${themeColors.gray}`}
                  href="https://twitter.com/medzcool">
                  Twitter
                </AnchorTag>
              </li>
            </ul>
          </Column>

          <Column>
            <div className="footer-section-title">CONTACT</div>
            <ul>
              <li>
                <a href="mailto:hello@medzcool.com" className="mailto">
                  hello@medzcool.com
                </a>
              </li>
            </ul>
          </Column>
        </Menu>
      </FooterInnerContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  background-color: ${themeColors.dark};
  padding: 60px 15px 60px 15px;
  .footer-section-title {
    font-size: 14px;
    font-family: ${fontFamily.sansSerif};
    font-weight: ${fontWeight.regular};
    color: ${themeColors.lightGray};
  }
  ${SmallText} {
    margin-top: 5px;
    color: ${themeColors.gray};
    font-weight: ${fontWeight.bold};
  }
  .policies {
    font-family: ${fontFamily.sansSerif};
    margin-top: 5px;
    color: ${themeColors.gray};
    font-size: 12px;
  }
  .mailto {
    color: ${themeColors.gray};
  }
  @media (max-width: 1024px) {
    padding: 60px 25px 60px 25px;
  }
`;

const LogoContainer = styled.div`
  .logo-text {
    font-weight: 700;
    font-family: ${fontFamily.sansSerif};
    color: white;
  }
`;

const FooterInnerContainer = styled.div`
  display: grid;
  height: 100%;
  max-width: 1300px;
  margin: auto;
  grid-template-columns: 2fr 4fr 2fr;
  @media (max-width: 1024px) {
    grid-template-columns: 3fr 5fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    ${LogoContainer} {
      margin-bottom: 35px;
    }
  }
`;

const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Column = styled.div`
  margin-bottom: 30px;
  ul {
    list-style: none;
    padding: 0;
    li {
      font-family: ${fontFamily.sansSerif};
      color: ${themeColors.gray};
      margin-bottom: 8px;
    }
  }
`;
const Newsletter = styled.div`
  ${SmallText} {
    margin-top: 5px;
    margin-bottom: 15px;
    color: ${themeColors.gray};
    font-weight: ${fontWeight.bold};
  }
  input {
    padding: 10px 20px;
    border-radius: 4px;
    border: none;
    max-width: 500px;
  }
`;
