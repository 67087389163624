import React, { Suspense, lazy } from "react";
import "./App.css";

import { mixpanelToken } from "./settings";
import mixpanel from "mixpanel-browser";
import { MixpanelContext } from "./Context/Context";

// Dependencies

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// Utility Components
import Spinner from "./Utilities/Spinner";
import { ServerError } from "./Utilities";

// Route Components
import Header from "./Header/Header";
import Homepage from "./Homepage/Homepage";
import HomepageForEncounters from "./Homepage/HomepageForEncounters";
import Login from "./Authentication/Login";
import LoginYC from "./Authentication/LoginYC";
import Terms from "./Terms";
import Privacy from "./Privacy";
import Refund from "./Refund";
import OnboardingApps from "./Onboarding/OnboardingApps";
import NoMatch from "./NoMatch";

// Apollo, GraphQL
import { useQuery, gql } from "@apollo/client";

// Lazy Route Components
const CranialLanding = lazy(() => import("./Cranial/CranialLanding"));
const AuscultateLanding = lazy(() => import("./Auscultate/AuscultateLanding"));
const KardiographLanding = lazy(() =>
  import("./Kardiograph/KardiographLanding")
);
const ClinicLanding = lazy(() => import("./Clinic/ClinicLanding"));
const FreeCollectionLanding = lazy(() =>
  import("./Collections/FreeCollectionLanding")
);
const SettingsLanding = lazy(() => import("./Settings/SettingsLanding"));
const Register = lazy(() => import("./Authentication/Register"));
const RegisterInline = lazy(() => import("./Authentication/RegisterInline"));
const ChangePassword = lazy(() => import("./Authentication/ChangePassword"));
const Case = lazy(() => import("./Rounds/Case"));
const Custom = lazy(() => import("./Custom/Custom"));
const Admin = lazy(() => import("./Admin/Admin"));
const Checkout = lazy(() => import("./Checkout/Checkout"));
const CollectionCheckout = lazy(() =>
  import("./Collections/CollectionCheckout")
);

const GET_USER = gql`
  query GET_USER {
    user {
      id
      isSuperuser
      username
      email
      profile {
        id
        subscribedApps {
          id
          title
          description
          url
          logo
          priceId
        }
        orders {
          id
        }
        subscribedRounds {
          id
          collectionTitle
          description
          thumbnail
          checkoutThumbnail
          collectionUrl
        }
        latestInvoiceId
        subscriptionStatus
        paymentIntentStatus
        stripeCustomerId
        subscriptionId
        priceId
        productId
        stripeCustomerPaymentMethodId
        stripeExpMonth
        stripeExpYear
        stripeLast4
        stripeCardBrand
        custom
      }
    }
  }
`;
// TODO #112 Remove mixpanel
mixpanel.init(mixpanelToken);

export default function App(props) {
  const { loading, error, data } = useQuery(GET_USER);
  if (loading) return <Spinner />;
  if (error) return <ServerError error={error} />;
  const user = data.user;

  return (
    <MixpanelContext.Provider value={mixpanel}>
      <Router>
        <Header user={user} {...props} />
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <Homepage {...props} user={user} />}
            />
            <Route
              exact
              path="/encounter-exit"
              render={(props) => (
                <HomepageForEncounters {...props} user={user} />
              )}
            />
            <Route
              path="/admin"
              render={(props) =>
                user && user.isSuperuser ? (
                  <Admin {...props} user={user} />
                ) : (
                  <Redirect to="/" />
                )
              }
            />
            <Route
              exact
              path="/login/:redirect?"
              render={(props) => <Login user={user} {...props} />}
            />
            <Route
              exact
              path="/login-auto/yc22-auth"
              render={(props) => <LoginYC user={user} {...props} />}
            />
            <Route
              exact
              path="/apps"
              render={(props) => <OnboardingApps user={user} {...props} />}
            />
            <Route
              exact
              path="/register"
              render={(props) => <Register user={user} {...props} />}
            />
            <Route
              exact
              path="/register/:redirect"
              render={(props) => <Register user={user} {...props} />}
            />
            <Route
              exact
              path="/register-inline/:email"
              render={(props) => <RegisterInline user={user} {...props} />}
            />
            <Route
              exact
              path="/register/custom/:customId"
              render={(props) => <Register user={user} {...props} />}
            />
            <Route
              exact
              path="/change-password"
              render={(props) => <ChangePassword user={user} {...props} />}
            />
            <Route
              exact
              path="/collection/checkout/:collectionUrl?"
              render={(props) => <CollectionCheckout {...props} user={user} />}
            />
            <Route
              exact
              path="/auscultate/:view?"
              render={(props) => <AuscultateLanding {...props} user={user} />}
            />
            <Route
              exact
              path="/cranial/:view?"
              render={(props) => <CranialLanding {...props} user={user} />}
            />
            <Route
              exact
              path="/kardiograph/:view?"
              render={(props) => <KardiographLanding {...props} user={user} />}
            />
            <Route
              path="/settings"
              exact
              render={(props) => <SettingsLanding {...props} user={user} />}
            />
            <Route
              exact
              path="/clinic/:view?/:plan?"
              render={(props) => <ClinicLanding {...props} user={user} />}
            />
            <Route
              exact
              path="/cranial/:view?"
              render={(props) => <CranialLanding {...props} user={user} />}
            />
            <Route
              exact
              path="/free-collection/:view?"
              render={(props) => (
                <FreeCollectionLanding {...props} user={user} />
              )}
            />
            <Route
              exact
              path="/terms"
              render={(props) => <Terms {...props} />}
            />
            <Route
              exact
              path="/privacy"
              render={(props) => <Privacy {...props} />}
            />
            <Route
              exact
              path="/refund"
              render={(props) => <Refund {...props} />}
            />
            <Route
              exact
              path="/cases/:caseId"
              render={(props) => <Case {...props} />}
            />
            <Route
              exact
              path="/flask"
              render={(props) => <Redirect to="/" />}
            />
            <Route
              exact
              path="/custom/:id?"
              render={(props) => <Custom {...props} user={user} />}
            />
            <NoMatch />
          </Switch>
        </Suspense>
      </Router>
    </MixpanelContext.Provider>
  );
}
