import React, { Fragment } from "react";
import { useSpring, animated } from "react-spring";
import { url, homeUrl } from "../settings";

// Dependencies
import { Link } from "react-router-dom";

// Styles
import styled from "styled-components";
import { themeColors, fontFamily, fontWeight } from "../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOut,
  faTimes,
  faUnlock,
  faHome,
  faCog,
  faLayerGroup,
  faHospitalAlt,
} from "@fortawesome/pro-light-svg-icons";

export default function Menu(props) {
  const { user, client } = props;

  function resetLogout() {
    client.resetStore();
    props.toggleMenu();
  }

  return (
    <Fragment>
      <MenuContainer style={props.style}>
        <div onClick={props.toggleMenu} className="close">
          <span>Close Menu</span>
          <FontAwesomeIcon icon={faTimes} />
        </div>

        <div className="drawer-menu-item">
          <a href="/">
            <FontAwesomeIcon icon={faHome} />
            <span>Dashboard</span>
          </a>
        </div>
        <div className="drawer-menu-item">
          <a href="https://rounds.medzcool.com/collections">
            <FontAwesomeIcon icon={faLayerGroup} />
            <span>Collections</span>
          </a>
        </div>
        <div className="drawer-menu-item">
          <a href="/clinic">
            <FontAwesomeIcon icon={faHospitalAlt} />
            <span>Clinic</span>
          </a>
        </div>
        <div className="drawer-menu-item">
          <a href="/change-password">
            <FontAwesomeIcon icon={faUnlock} />
            <span>Change Password</span>
          </a>
        </div>

        {user.profile.subscriptionStatus && (
          <div className="drawer-menu-item">
            <a href="/settings">
              <FontAwesomeIcon icon={faCog} />
              <span>Settings &amp; Billing</span>
            </a>
          </div>
        )}

        <div
          className="drawer-menu-item"
          onClick={() => {
            props
              .logout()
              .then(() => resetLogout())
              .then(() => (window.location.href = "/"));
          }}>
          <FontAwesomeIcon icon={faSignOut} />
          <span>Logout</span>
        </div>
      </MenuContainer>
      <MenuBackground style={props.opacity} onClick={props.toggleMenu} />
    </Fragment>
  );
}

const MenuContainer = styled(animated.div)`
  font-family: ${fontFamily.sansSerif};
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px;
  color: white;
  background: #1d1d1d;
  z-index: 1;
  padding: 25px;
  .drawer-menu-item {
    cursor: pointer;
    span {
      margin-left: 15px;
      font-weight: 500;
    }
    a {
      color: white;
    }
    padding: 10px;
    margin: 15px 0;
  }
  .close {
    cursor: pointer;
    text-align: right;
    margin-bottom: 25px;
    border-bottom: 1px solid gray;
    padding-bottom: 25px;
    span {
      margin-right: 15px;
    }
  }
  @media (max-width: 425px) {
    width: 100%;
    padding: 0;
    .close {
      padding: 25px;
    }
    .drawer-menu-item {
      text-align: center;
    }
  }
`;
const MenuBackground = styled(animated.div)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: black;
`;
