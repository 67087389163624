import React, { useState, useEffect } from "react";

// Settings
import { BreakPoints } from "../DesignSettings";

// Dependencies
import { Link, Redirect } from "react-router-dom";
import { mixpanelToken } from "../settings";
import backgroundImg from "../static/medical-icons-background.jpg";

// Styles
import styled from "styled-components";
import {
  Title,
  MediumText,
  SmallText,
  BodyCopy,
  SmallTitle,
} from "../Typography";

import { Line } from "../Elements";
import Spinner from "../Utilities/Spinner";
import Button from "../Utilities/Button";

import { fontFamily, yellow } from "../Cytoplasm";

import { useMutation } from "@apollo/client";
import { LOGIN } from "./AuthQL";

import { GET_USER } from "../DataStructures/Queries";

const mixpanel = require("mixpanel-browser");
// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init(mixpanelToken);

export default function Login(props) {
  const [username, setUsername] = useState("");
  function handleUsername(e) {
    setUsername(e.target.value);
    handleButtonActive();
  }
  const [password, setPassword] = useState("");
  function handlePassword(e) {
    setPassword(e.target.value);
    handleButtonActive();
  }

  useEffect(() => {
    if (username.length > 0 && password.length > 0) {
      setFormFilled(true);
    }
  }, [username, password]);

  const [formFilled, setFormFilled] = useState(false);
  function handleButtonActive() {
    if (username.length > 0 && password.length > 0) {
      setFormFilled(true);
    }
  }

  function handleLogin(e, login) {
    e.preventDefault();
    if (username.length > 0 && password.length > 0) {
      login();
    }
  }

  const [formSubmitted, setFormSubmitted] = useState(false);
  function handleSubmit(e, login) {
    e.preventDefault();

    if (username.length > 0 && password.length > 0) {
      login();
      setFormSubmitted(true);
    }
  }
  const [cannotAuthMsg, setCannotAuthMsg] = useState();
  function cannotAuth(msg) {
    setCannotAuthMsg(msg);
  }

  const [login, { data, loading, error }] = useMutation(LOGIN, {
    variables: { username: username, password: password },
    update: (cache, { data: { login } }) => {
      console.log(login);
      if (login.user) {
        cannotAuth(
          "The username and/or password do not match. Please try again."
        );
      }
      // cache.writeData({ data: { user: data.data.login.user } });
      cache.writeQuery({
        query: GET_USER,
        data: {
          user: login.user,
        },
      });
    },
    onCompleted: (data) => {
      if (!data.login.user) {
        cannotAuth(
          "The username and/or password do not match. Please try again."
        );
      } else {
        if (props.match.params.redirect) {
          switch (props.match.params.redirect) {
            case "rounds":
              window.location = "https://rounds.medzcool.com";
              break;
            case "auscultate":
              props.history.push(
                "/" + props.match.params.redirect + "/confirm-purchase"
              );
              break;
            case "cranial":
              props.history.push(
                "/" + props.match.params.redirect + "/confirm-purchase"
              );
              break;
            case "clinic":
              props.history.push("/" + props.match.params.redirect);
              break;
          }
        } else {
          mixpanel.identify(data.login.user.id);

          mixpanel.people.set({
            $email: data.login.user.email,
          });

          mixpanel.track("Login", {
            Location: "Isolated Login",
          });
          props.history.push("/");
        }
      }
    },
  });

  if (props.user) {
    return <Redirect to="/" />;
  }

  return (
    <LoginContainer>
      <div className="login-header">
        <Title>Login to Medzcool</Title>
      </div>

      <div className="login-background">
        <>
          {!error && !cannotAuthMsg && formSubmitted ? (
            <Spinner />
          ) : (
            <FormContainer>
              {cannotAuthMsg && (
                <MediumText className="login-error">
                  <p>{cannotAuthMsg}</p>
                </MediumText>
              )}

              {error && (
                <MediumText className="login-error">
                  <p>{error.graphQLErrors[0].message}</p>
                </MediumText>
              )}

              <form onSubmit={(e) => handleSubmit(e, login)}>
                <input
                  type="text"
                  value={username}
                  placeholder="Username or Email"
                  onChange={handleUsername}
                />
                <input
                  type="password"
                  value={password}
                  placeholder="Password"
                  onChange={handlePassword}
                />
                <Button
                  buttonLabel="Sign-In"
                  active={formFilled}
                  onClick={(e) => handleLogin(e, login)}
                  type="submit"
                />
              </form>
            </FormContainer>
          )}
        </>
      </div>

      <div className="bottom">
        <span className="bottom-items">
          <a href="https://api.medzcool.com/accounts/password_reset">
            Forgot Password?
          </a>
        </span>
        &middot;
        <span className="bottom-items">
          <Link to="/register">Don't have an account?</Link>
        </span>
        &middot;
        <span className="bottom-items">
          <Link to="/privacy">Privacy Policy</Link>
        </span>
      </div>

      <BackgroundImg />
    </LoginContainer>
  );
}

const LoginContainer = styled.div`
  padding: 15px;
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
  .login-header {
    margin-bottom: 25px;
    text-align: center;
  }
  .login-background {
    background: white;
    padding: 25px;
    border-radius: 15px;
    border: 1px solid #e8e8e8;
  }
  .bottom {
    margin-top: 25px;
    text-align: center;
    .bottom-items {
      margin-right: 5px;
      margin-left: 5px;
      a {
        color: black;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  ${Title} {
    margin-bottom: 0px;
    font-weight: 500;
  }
  .consent {
    margin-top: 50px;
  }
  a {
    color: #062bba;
  }
  @media (max-width: ${BreakPoints.mobile}) {
    .login-background {
      border: none;
    }
  } ;
`;
const FormContainer = styled.div`
  margin-bottom: 25px;
  input {
    box-sizing: border-box;
    padding: 15px;
    width: 100%;
    border-radius: 3px;
    border: lightgray 1px solid;
    margin-bottom: 15px;
    font-size: 16px;
    font-family: ${fontFamily.sansSerif};
  }
  .login-error {
    background-color: ${yellow.light};
    padding: 8px 15px;
    margin-bottom: 15px;
    border-radius: 5px;
  }
`;
const BackgroundImg = styled.div`
  margin-bottom: 0;
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-size: 600px;
  background-image: url(${backgroundImg});
  background-position: center center;
  background-repeat: repeat;
  background-attachment: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  @media (max-width: ${BreakPoints.mobile}) {
    display: none;
  } ;
`;
