import styled from "styled-components";
import { fontFamily } from "./Typography";

export const BaseContainer = styled.div`
  max-width: 1300px;
  margin: auto;
  margin-bottom: 100px;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  font-family: ${fontFamily.sansSerif};
  box-sizing: border-box;
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.2em;
  }
  p {
    font-family: ${fontFamily.serif};
  }
  input,
  button {
    font-size: 18px;
    font-family: ${fontFamily.sansSerif};
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;
