import { gql } from "@apollo/client"

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      user {
        id
        isSuperuser
        username
        email
        profile {
          id
          subscribedApps {
            id
            title
            description
            url
            logo
            priceId
          }
          orders {
            id
          }
          subscribedRounds {
            id
            collectionTitle
            description
            thumbnail
            checkoutThumbnail
            collectionUrl
          }
          latestInvoiceId
          subscriptionStatus
          paymentIntentStatus
          stripeCustomerId
          subscriptionId
          priceId
          productId
          stripeCustomerPaymentMethodId
          stripeExpMonth
          stripeExpYear
          stripeLast4
          stripeCardBrand
          custom
        }
      }
    }
  }
`;

export const REGISTER = gql`
  mutation register($name: String!, $email: String!, $password: String!, $location: String) {
    register(name: $name, email: $email, password: $password, location: $location) {
      user {
        id
        isSuperuser
        username
        email
        profile {
          id
          subscribedApps {
            id
            title
            description
            url
            logo
            priceId
          }
          orders {
            id
          }
          subscribedRounds {
            id
            collectionTitle
            description
            thumbnail
            checkoutThumbnail
            collectionUrl
          }
          latestInvoiceId
          subscriptionStatus
          paymentIntentStatus
          stripeCustomerId
          subscriptionId
          priceId
          productId
          stripeCustomerPaymentMethodId
          stripeExpMonth
          stripeExpYear
          stripeLast4
          stripeCardBrand
          custom
        }
      }
    }
  }
`;
