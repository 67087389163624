import React from "react";

// Styles
import styled from "styled-components";
import {
  TopLabel,
  BodyCopy,
  fontFamily,
  Title,
  AnchorTag,
} from "../Typography";
import { ContentBlock } from "../Elements";

// Dependencies
import YoutubeEmbed from "./YoutubeEmbed";

export default function VideoBlock(props) {
  const { mixpanel } = props;
  const youtubeID = "gqiqcO6AiWc";
  const videoTitle = "The Cardiac Cycle on an EKG";
  const videoDescription =
    "The cardiac cycle represents electrical depolarization through the heart, starting at the SA node towards the AV node and then down to the ventricles.";

  return (
    <ContentBlock>
      <BackgroundContainer>
        <VideoBlockContainer>
          <div className="featured-video-mobile">
            <TopLabel>Featured Video</TopLabel>
            <Title>{videoTitle}</Title>
            <YoutubeEmbed youtubeId={youtubeID} />
          </div>
          <div className="featured-video-text">
            <div className="featured-video-desktop">
              <TopLabel>Featured Video</TopLabel>
              <Title>{videoTitle}</Title>
            </div>
            <BodyCopy>{videoDescription}</BodyCopy>
            <BodyCopy style={{ marginTop: 10 }}>
              <AnchorTag
                href={"https://youtu.be/" + youtubeID}
                className="learn-more"
                onClick={() => {
                  mixpanel.track("Homepage", {
                    Category: "Watch",
                    "Video Type": "Featured",
                    "Video Title": "The Cardiac Cycle on an EKG",
                    VideoID: "gqiqcO6AiWc",
                    Action: "Clicked",
                    "Click Action": "Watch Now",
                  });
                }}>
                Watch Now
              </AnchorTag>
            </BodyCopy>
          </div>
          <div className="featured-video-desktop">
            <YoutubeEmbed youtubeId={youtubeID} />
          </div>
        </VideoBlockContainer>
      </BackgroundContainer>
    </ContentBlock>
  );
}
const BackgroundContainer = styled.div`
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #f5f8ff;
`;

const VideoBlockContainer = styled.div`
  padding: 75px 25px;
  max-width: 1300px;
  margin: auto;
  font-family: ${fontFamily.sansSerif};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
  align-items: center;
  .featured-video-mobile {
    display: none;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 25px;
    align-items: center;
    .featured-video-mobile {
      display: block;
    }
    .featured-video-desktop {
      display: none;
    }
  }
`;
