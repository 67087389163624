import React, { Fragment } from "react";

// Styles
import styled from "styled-components";
import Footer from "./Footer/Footer";

export default function Privacy(props) {
  return (
    <Fragment>
      <TermsContainer>
        <div className="document-container">
          <h1 className="bold">Return & Refund Policy</h1>
          <div className="document">
            <p>
              If you’re looking to return and receive a refund for your order
              for whatever reason, we're here to help! We offer free
              returns/refunds within 14 days of receiving your order. You can
              return your product for a full refund to the original payment
              method.
            </p>
            <p>
              Please note the following exceptions to our return and refund
              policy:
            </p>

            <ul>
              <li>Discounted items are final and cannot be refunded</li>
            </ul>

            <p>
              Initiating a return or refund is easy. Simply send us an
              email to{" "}
              <a href="mailto:hello@medzcool.com" target="_top">
                hello@medzcool.com
              </a>
              , and include:{" "}
            </p>
            <ul>
              <li>
                The email associated with your Medzcool account you used to make
                the purchase.
              </li>
              <li>The product or software you want refunded.</li>
              <li>The reason you want a refund (optional).</li>
            </ul>

            <p>Additional Information:</p>

            <ul>
              <li>
                Refunds can take up to 24 - 48hrs for our team to review and
                process.
              </li>
              <li>
                After a refund is approved and processed, a credit back to the
                original payment method can be expected within 5 business days.
              </li>
              <li>
                If you have any additional questions or concerns, feel free to
                email us at:{" "}
                <a href="mailto:hello@medzcool.com" target="_top">
                  hello@medzcool.com
                </a>
                , and we'd be glad to help.
              </li>
            </ul>
          </div>
        </div>
      </TermsContainer>
      <Footer />
    </Fragment>
  );
}

const TermsContainer = styled.div`
  font-family: futura-pt, sans-serif;
  padding: 50px 15px 100px 15px;
  margin-top: 75px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
`;
