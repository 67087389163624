import React, { useEffect } from "react";
// DEPENDENCIES
// COMPONENTS
// STYLES

import { LOGIN } from "./AuthQL";
import { useMutation } from "@apollo/client";
import { GET_USER } from "../DataStructures/Queries";

export default function LoginYC(props) {
  console.log("yo");
  const [login, { data, loading, error }] = useMutation(LOGIN, {
    variables: { username: "yc2022@medzcool.com", password: "Ms242820" },

    onCompleted: (data) => {
      console.log(data);
      window.location.href = "/";
    },
  });

  useEffect(() => {
    login();
  }, []);

  return null;
}
