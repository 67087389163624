import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import { mixpanelToken } from "../settings";

// Components
import mixpanel from "mixpanel-browser";
import OurTools from "./OurTools/OurTools";
import HomepageLanding from "./HomepageLanding";
import HomepageCollections from "./HomepageCollections";
import HorizontalResponsiveAd from "../Ads/HorizontalResponsiveAd";
import Cranial from "./Cranial";
import Dashboard from "./Dashboard/Dashboard";
import ClinicalRoundsBanner from "./ClinicalRoundsBanner";
import FeaturedAppAlt from "./FeaturedAppAlt";
import FeaturedApp from "./FeaturedApp";
import VideoBlock from "./VideoBlock";
import GridDisplay from "./GridDisplay";
import FeaturedClinicApp from "./FeaturedClinicApp";
import YoutubeEmbed from "./YoutubeEmbed";
import ClinicalCase from "./ClinicalCase";
import Footer from "../Footer/Footer";
import MoreClinicalCases from "./MoreClinicalCases";
import TopRibbon from "./TopRibbon";
import { ContentBlock, ContentLabel, ContentLabelContainer } from "../Elements";
import { MediumText, BodyCopy, AnchorTag } from "../Typography";

// Styles
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

// Dependencies

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init(mixpanelToken);

export default function Homepage(props) {
  const { user } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user) {
      mixpanel.identify(user.id);
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Medzcool: MedEd Videos, Apps &amp; Software</title>
        <meta
          name="description"
          content="Engaging medical education for medical students and healthcare professionals using technology and graphic design."
        />
      </Helmet>

      {!props.user && <TopRibbon {...props} mixpanel={mixpanel} />}

      {!props.user && <HomepageLanding />}

      <HomepageContainer>
        <Dashboard user={props.user} mixpanel={mixpanel} />

        <OurTools {...props} />

        <FeaturedApp user={user} mixpanel={mixpanel} />

        <VideoBlock mixpanel={mixpanel} />

        <ContentBlock MoreVideos>
          <ContentLabelContainer>
            <ContentLabel>More Videos</ContentLabel>
            <BodyCopy>
              Videos from our Youtube Channel. For more free videos,{" "}
              <AnchorTag
                href="https://youtube.com/medzcool"
                onClick={() => {
                  mixpanel.track("Homepage", {
                    Category: "Watch",
                    Action: "Clicked",
                    "Click Action": "Visit Our Channel",
                  });
                }}
              >
                visit our channel
              </AnchorTag>
            </BodyCopy>
          </ContentLabelContainer>

          <GridDisplay>
            <YoutubeEmbed youtubeId={"wyNZkEmhZjI"} />
            <YoutubeEmbed youtubeId={"s-vT-6oKW2Q"} />
            <YoutubeEmbed youtubeId={"QStk4uUQ7fU"} />
            <YoutubeEmbed youtubeId={"anPLauNThNk"} />
            <YoutubeEmbed youtubeId={"Pao8171B354"} />
            <YoutubeEmbed youtubeId={"pZWBK8u7wVw"} />

            <SeeMore>
              <MediumText>
                <a
                  href="https://youtube.com/medzcool"
                  onClick={() => {
                    mixpanel.track("Homepage", {
                      Category: "Watch",
                      Action: "Clicked",
                      "Click Action": "See More Videos",
                    });
                  }}
                >
                  See More Videos <Chevron icon={faChevronRight} />
                </a>
              </MediumText>
            </SeeMore>
          </GridDisplay>
        </ContentBlock>

        <Cranial user={props.user} mixpanel={mixpanel} {...props} />

        <ClinicalRoundsBanner />
        <ContentBlock>
          <ClinicalCase mixpanel={mixpanel} />
        </ContentBlock>
        <ContentBlock>
          <MoreClinicalCases mixpanel={mixpanel} />
        </ContentBlock>

        <HomepageCollections mixpanel={mixpanel} />
      </HomepageContainer>
      <Footer />
    </Fragment>
  );
}
const HomepageContainer = styled.div`
  max-width: 1300px;
  margin: auto;
  padding-top: 50px;
  ${ContentBlock} {
    margin-bottom: 150px;
  }
  @media (max-width: 1024px) {
    padding-top: 25px;
  }
  @media (max-width: 768px) {
    ${ContentBlock} {
      margin-bottom: 75px;
    }
  }
`;

const SeeMore = styled.div`
  a {
    text-decoration: none;
    color: #062bba;
  }
  @media (max-width: 425px) {
    margin-top: 15px;
  }
`;

const Chevron = styled(FontAwesomeIcon)`
  vertical-align: -3px;
  margin-left: 6px;
`;
