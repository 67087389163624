import React, { useState, useEffect } from "react";
import { homeUrl } from "../settings";

// Components
import { ContentBlock, ContentLabel } from "../Elements";

// Typography
import { Title, AnchorTag, BodyCopy, SmallTitle } from "../Typography";

// Styles
import styled from "styled-components";
import { themeColors, fontFamily, fontWeight, blue } from "../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

// Dependencies
import { Link } from "react-router-dom";

export default function FeaturedClinicApp(props) {
  const appDetails = {
    imgSrcProp:
      "https://medzcool.s3-us-west-2.amazonaws.com/clinic/clinic-homepage-thumb-alt.png",
    imgSrcPropMobile:
      "https://medzcool.s3-us-west-2.amazonaws.com/clinic/clinic-homepage-thumb.jpg",
    imgAltProp: "Clinic",
    title: "Clinic",
    description:
      "A simulated medical clinic. New patient encounters, every week.",
    altDescription:
      "Get access to challenging multiple-choice medical encounters, ranging from common diseases to rare pathologies, every week.",
    mobileDescription:
      "A simulated medical clinic, where new patient encounters present every week, ranging from common diseases to rare pathologies.",
    link: `${homeUrl}/clinic`,
    category: "Clinic",
  };

  const { user, mixpanel } = props;

  if (user && user.profile.subscriptionStatus) return null;

  return (
    <FeaturedAppContainer>
      <div className="desktop">
        <div className="featured-label">Featured App</div>
        <div className="title">{appDetails.title}</div>
        <div className="top-text">
          <div className="description">New patient encounters, every week</div>
          <Link
            to="/clinic"
            onClick={() => {
              mixpanel.track("Homepage", {
                App: "Clinic",
                Action: "Clicked",
                "Click Action": "Learn More",
              });
            }}>
            <span>Learn More</span>{" "}
            <FontAwesomeIcon icon={faChevronRight} size="sm" />
          </Link>
        </div>

        <img src={appDetails.imgSrcProp} alt={appDetails.imgAltProp} />

        <div className="bottom-alt">
          <div>{appDetails.altDescription}</div>
          <StyledButton
            className="get"
            onClick={() => {
              mixpanel.track("Homepage", {
                App: "Clinic",
                Action: "Clicked",
                "Click Action": "Get App",
              });
              window.location = appDetails.link;
            }}>
            Get App
          </StyledButton>
        </div>
      </div>
    </FeaturedAppContainer>
  );
}
const StyledButton = styled.button`
  font-family: ${fontFamily.sansSerif};
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  background: ${blue.bright};
  color: white;
  &.get {
    margin-top: 15px;
  }
  @media (max-width: 425px) {
    &.get {
      margin-top: 10px;
    }
  }
`;

const FeaturedAppContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  padding: 25px;
  margin-top: 45px;
  text-align: center;
  .top-text {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
  .title {
    font-size: 60px;
    font-weight: 700;
  }
  .featured-label {
    font-weight: ${fontWeight.light};
    font-size: 16px;
  }
  .description {
    font-size: 22px;
  }
  .bottom-alt {
    font-size: 22px;
    position: absolute;
    bottom: 50px;
    max-width: 700px;
    left: 0;
    right: 0;
    margin: auto;
    .get {
      font-size: 22px;
      max-width: 250px;
      border-radius: 5px;
      margin-top: 25px;
    }
  }
  span {
    position: relative;
    top: -2px;
    left: -5px;
  }
  a {
    color: ${blue.bright};
    font-size: 20px;
    margin-top: 15px;
    font-weight: 500;
    text-decoration: none;
    display: block;
  }
  .desktop img {
    max-width: 700px;
    margin: auto;
    object-fit: cover;
  }
  .mobile {
    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
    }
  }
  .desktop {
    position: relative;
    img {
      position: relative;
      top: -65px;
      z-index: -1;
    }
  }
  .mobile {
    display: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 5px 5px 15px 5px;
    margin: 15px 15px 0 15px;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    .mobile-photo-container {
      position: relative;
      .mobile-title {
        position: absolute;
        padding: 15px;
      }
    }
    .mobile-description {
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: left;
    }
  }
  .mobile-bottom-container {
    padding: 0 10px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    img {
      height: 350px;
    }
  }
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-column-gap: 50px;
    grid-template-columns: 100%;
    padding: 0;
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
  @media (max-width: 425px) {
    a {
      margin-top: 0;
    }
    img {
      height: 250px;
    }
    ${AnchorTag} {
      font-size: 12px;
    }
  }
`;
