import React from "react";

import mixpanel from "mixpanel-browser";

export default function YoutubeEmbed(props) {
  const { youtubeId } = props;

  return (
    <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        height: 0,
      }}>
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title={`${youtubeId}`}
      />
    </div>
  );
}
