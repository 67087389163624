import React from "react";
import { mixpanelToken } from "../../settings";

// DEPENDENCIES
import mixpanel from "mixpanel-browser";

// COMPONENTS
import Spinner from "../../Utilities/Spinner";
import { ServerError } from "../../Utilities";
import AppCard from "./OurAppCards";
import GridDisplay from "../GridDisplay";
import YoutubeEmbed from "../YoutubeEmbed";
import HomepageCollections from "../HomepageCollections";

// STYLES
import styled from "styled-components";
import { fontFamily, Title, LargeText, BodyCopy } from "../../Typography";
import { ContentBlock } from "../../Elements";

// DATA
import { useQuery, gql } from "@apollo/client";

const APPS = gql`
  query GET_APPS {
    apps {
      id
      title
      description
      url
      logo
    }
  }
`;

mixpanel.init(mixpanelToken);

export default function OurTools(props) {
  const { loading, error, data } = useQuery(APPS);
  if (loading) return <Spinner />;
  if (error) return <ServerError error={error} />;
  const apps = data.apps;

  return (
    <OurAppsContainer>
      <AppsContainer>
        <div className="grid">
          <div className="grid-left">
            <Title>Our Apps</Title>
            <p>
              Interactive and engaging web applications to help you learn
              medicine and health science.
            </p>
          </div>
          <div className="grid-right">
            <AppGrid>
              <AppCard
                title="Clinic"
                url="https://clinic.medzcool.com"
                description="A simulated clinic with new patient encounters every week"
                logo="https://medzcool.s3-us-west-2.amazonaws.com/clinic/clinic-icon-sm.png"
                mixpanel={mixpanel}
              />

              {apps.map((app) => {
                return <AppCard key={app.id} {...app} mixpanel={mixpanel} />;
              })}

              <AppCard
                title="Crash Cart"
                url=""
                description="An interactive way to learn the BLS and ACLS algorithms."
                logo="https://medzcool-images.s3.amazonaws.com/crash-cart-logo-sm.png"
                mixpanel={mixpanel}
                comingSoon="Coming 2024"
              />
              {/* <AppCard
                title="Pixel"
                url=""
                description="Reusable medical graphics to use in your projects. Royalty-Free."
                logo="https://medzcool-images.s3.amazonaws.com/Pixel-logo-small.png"
                mixpanel={mixpanel}
                comingSoon="Coming Late 2023"
              /> */}
            </AppGrid>
          </div>
        </div>
      </AppsContainer>
    </OurAppsContainer>
  );
}
const OurAppsContainer = styled.div`
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #f5f8ff;
`;

const AppsContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  padding: 75px 25px;
  max-width: 1300px;
  margin: auto;
  ${Title} {
    font-weight: 500;
    margin-bottom: 0;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 60px;
  }
  p {
    margin-top: 15px;
    font-size: 24px;
  }
  @media (max-width: 1000px) {
    padding: 75px 25px;
  }
  @media (max-width: 770px) {
    p {
      margin-top: 10px;
      font-size: 18px;
    }
    .grid {
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
  }
  @media (max-width: 450px) {
    padding: 50px 10px 75px 10px;
    p {
      font-size: 1.1em;
    }
    .grid {
      .grid-left {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
`;

const AppGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 770px) {
    /* grid-template-columns: 1fr 1fr; */
  }
  @media (max-width: 450px) {
    /* grid-template-columns: 1fr 1fr; */
    grid-gap: 10px;
  }
`;
