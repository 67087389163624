import React from "react";

// Dependencies

//Components

// Styles
import styled from "styled-components";
import { red, blue, fontFamily } from "../Cytoplasm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

export default function TopRibbon(props) {
  function handleClick() {
    window.location = "https://personas.medzcool.com";
    // console.log("clicked top ribbon");
  }
  return (
    <TopRibbonContainer onClick={handleClick}>
      <div className="inner">
        <span className="new-app">NEW APP</span>
        <span className="main">
          <strong>Personas</strong>: AI simulated patient encounters
        </span>

        <FontAwesomeIcon icon={faChevronRight} size="sm" />
      </div>
    </TopRibbonContainer>
  );
}
const TopRibbonContainer = styled.div`
  cursor: pointer;
  background: ${blue.bright};
  margin-top: 50px;
  width: 100%;
  font-family: ${fontFamily.sansSerif};
  .inner {
    padding: 10px;
    text-align: center;
    color: white;
    .new-app {
      padding: 3px 12px;
      margin-right: 15px;
      background: ${blue.neutral};
      border-radius: 5px;
      font-size: 12px;
      font-weight: bold;
    }
    .main {
      margin-right: 15px;
    }
  }
  @media (max-width: 425px) {
    .inner {
      padding: 8px 5px;
      .new-app {
        font-size: 10px;
        padding: 2px 5px;
        margin-right: 10px;
      }
      .main {
        font-size: 14px;
        margin-right: 10px;
      }
    }
  }
`;
