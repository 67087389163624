import React from "react";

// Styles
import styled from "styled-components";
import {
  Title,
  SmallTitle,
  AnchorTag,
  BodyCopy,
  fontFamily,
  fontWeight,
} from "../Typography";
import { ContentBlock } from "../Elements";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-light-svg-icons";

export default function HomepageCollections(props) {
  const { mixpanel } = props;

  const collectionList = [
    {
      imgSrcProp:
        "https://medzcool-static.s3.amazonaws.com/media/media/Defibrillator_eQEvqxQ.jpg",
      imgAltProp: "Cardiac Arrest",
      title: "Advance Cardiac Life Support",
      slug: "cardiac-arrest",
      description:
        "A review of the ACLS cardiac arrest, bradycardia and tachycardia guidelines through interactive multiple choice clinical questions.",
      link: "https://rounds.medzcool.com/collection/cardiac-life-support",
      label: "Visit Cardiac Arrest Collection",
    },
    {
      imgSrcProp:
        "https://medzcool.s3-us-west-2.amazonaws.com/Rounds/poisons.jpg",
      imgAltProp: "Poisons, Overdoses and Antidotes",
      title: "Poisons, Overdoses and Antidotes",
      slug: "poisons",
      description:
        "A collection focusing on exposures, poisons, overdose and various antidotes. This is a clinical rounds collection on everything toxicology, from pathology to the identification of antidotes.",
      link: "https://rounds.medzcool.com/collection/poisons",
      displayType: "list",
    },
    {
      imgSrcProp:
        "https://medzcool.s3-us-west-2.amazonaws.com/Rounds/pediatrics_bear.jpg",
      imgAltProp: "Pediatrics",
      title: "Pediatrics",
      slug: "pediatrics",
      description:
        "A collection exploring some common and some not so common pediatric clinical presentations.",
      link: "https://rounds.medzcool.com/collection/pediatrics",
    },
  ];

  return (
    <ContentBlock>
      <Title style={{ marginBottom: 0 }}>Clinical Collections</Title>
      <BodyCopy style={{ marginBottom: 50, marginTop: 0 }}>
        Collections of related cases grouped together by pathology, category or
        theme.{" "}
        <a
          href="https://rounds.medzcool.com/collections"
          className="learn-more"
          onClick={() => {
            mixpanel.track("Homepage", {
              App: "Collections",
              Action: "Clicked",
              "Click Action": "See More",
              "See More Location": "Top",
            });
          }}>
          See more collections.
        </a>
      </BodyCopy>

      <CollectionContainer>
        {collectionList.map((collection) => (
          <CollectionListContainer key={collection.slug}>
            <AnchorTag
              href={collection.link}
              onClick={() => {
                mixpanel.track("Homepage", {
                  App: "Collections",
                  Collection: collection.title,
                  Action: "Clicked",
                  "Click Action": "Collection Photo",
                });
              }}>
              <img src={collection.imgSrcProp} alt={collection.imgAltProp} />
            </AnchorTag>
            <div className="article-text-container">
              <SmallTitle>{collection.title}</SmallTitle>
              <BodyCopy
                className="collection-description"
                style={{ marginBottom: 10 }}>
                {collection.description}
              </BodyCopy>
              <BodyCopy>
                <AnchorTag
                  href={collection.link}
                  onClick={() => {
                    mixpanel.track("Homepage", {
                      App: "Collections",
                      Collection: collection.title,
                      Action: "Clicked",
                      "Click Action": "See Collection",
                    });
                  }}>
                  See Collection
                </AnchorTag>
              </BodyCopy>
            </div>
          </CollectionListContainer>
        ))}
      </CollectionContainer>

      <BodyCopy style={{ marginTop: 50 }}>
        <AnchorTag
          href="https://rounds.medzcool.com"
          onClick={() => {
            mixpanel.track("Homepage", {
              App: "Collections",
              Action: "Clicked",
              "Click Action": "See More",
              "See More Location": "Bottom",
            });
          }}>
          See More Collections <Chevron icon={faChevronRight} />
        </AnchorTag>
      </BodyCopy>
    </ContentBlock>
  );
}
const CollectionContainer = styled.div``;

const CollectionListContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-column-gap: 15px;
  max-width: 1000px;
  margin-bottom: 15px;
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .article-text-container {
    font-family: ${fontFamily.sansSerif};
    padding: 25px;
    .label {
      font-weight: ${fontWeight.light};
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 13px;
    }
  }
  @media (max-width: 1024px) {
    grid-column-gap: 15px;
    .article-text-container {
      padding: 15px;
      .label {
        margin-bottom: 0px;
      }
    }
    img {
      height: 200px;
    }
  }
  @media (max-width: 768px) {
    grid-column-gap: 5px;
    grid-template-columns: 3fr 5fr;
    .article-text-container {
      padding: 15px;
    }
    img {
      height: 200px;
    }
  }
  @media (max-width: 425px) {
    grid-column-gap: 5px;
    grid-template-columns: 3fr 6fr;
    margin-bottom: 25px;
    .article-text-container {
      padding: 8px;
    }
    img {
      height: 125px;
    }
    .collection-description {
      display: none;
    }
  }
`;

const Chevron = styled(FontAwesomeIcon)`
  margin-left: 3px;
`;
