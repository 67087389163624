import React, { Fragment } from "react";

// Styles
import styled from "styled-components";
import Footer from "./Footer/Footer";

export default function Privacy(props) {
  return (
    <Fragment>
      <TermsContainer>
        <div className="document-container">
          <h1 className="bold">Privacy Policy</h1>
          <div className="document">
            <p>
              Your privacy is critically important to us. At Medzcool we have
              a few fundamental principles:
            </p>
            <ol>
              <li>
                We don’t ask you for personal information unless we truly need
                it. (We can’t stand services that ask you for things like your
                gender or income level for no apparent reason.)
              </li>
              <li>
                We don’t share your personal information with anyone except to
                comply with the law, develop our products, or protect our
                rights.
              </li>
              <li className="last">
                We don’t store personal information on our servers unless
                required for the on-going operation of one of our services.
              </li>
            </ol>
            <p> Below is our privacy policy which incorporates these goals:</p>
            <p>
              {" "}
              Medzcool LLC (<strong>Medzcool</strong>) operates
              Medzcool.com. It is Medzcool’s policy to respect your privacy
              regarding any information we may collect while operating our
              websites.
            </p>
            <h3 className="bold">Website Visitors</h3>
            <p>
              {" "}
              Like most website operators, Medzcool collects
              non-personally-identifying information of the sort that web
              browsers and servers typically make available, such as the browser
              type, language preference, referring site, and the date and time
              of each visitor request. Medzcool’s purpose in collecting
              non-personally identifying information is to better understand how
              Medzcool’s visitors use its website. From time to time,
              Medzcool may release non-personally-identifying information in
              the aggregate, e.g., by publishing a report on trends in the usage
              of its website.
            </p>
            <p>
              {" "}
              Medzcool also collects potentially personally-identifying
              information like Internet Protocol (IP) addresses. Medzcool does
              not use such information to identify its visitors, however, and
              does not disclose such information, other than under the same
              circumstances that it uses and discloses personally-identifying
              information, as described below.
            </p>
            <h3 className="bold" id="personal-info">
              Gathering of Personally-Identifying Information
            </h3>
            <p>
              {" "}
              Certain visitors to Medzcool's websites choose to interact with
              Medzcool in ways that require Medzcool to gather
              personally-identifying information. The amount and type of
              information that Medzcool gathers depends on the nature of the
              interaction. For example, we ask visitors who sign up account at{" "}
              <a href="https://Medzcool.com">medzcool.com</a> to provide a
              username and email address. Medzcool collects such information
              only insofar as is necessary or appropriate to fulfill the purpose
              of the visitors interaction with Medzcool. Medzcool does not
              disclose personally-identifying information other than as
              described below. And visitors can always refuse to supply
              personally-identifying information, with the caveat that it may
              prevent them from engaging in certain website-related activities.
            </p>
            <h4 className="bold">Age Requirements and Birthdate</h4>
            <p>
              In compliance with the{" "}
              <a href="https://www.ftc.gov/enforcement/rules/rulemaking-regulatory-reform-proceedings/childrens-online-privacy-protection-rule">
                Children's Online Privacy Protection Rule ("COPPA")
              </a>
              , and Medzcool's policies, Medzcool users truthfully
              acknowledge their age to be greater than 18 years of age upon registration.
              Registered users may also be asked to provide their birthdate to
              confirm this age requirements. Medzcool may use this information
              to improve website content, webbsite features, may display this
              information publicly or provide it to others. However, Medzcool
              does not disclose personally-identifying information other than as
              described below.
            </p>
            <h4 className="bold">Data Tracking</h4>
            <p>
              As a registered user, Medzcool collects and stores your data and
              information for the purposes of providing your with a better user
              experience and allowing your to access the full functionality of
              the Medzcool application. Your data is encrypted and securly
              stored on our servers. When you interact with the Medzcool
              application, we may process additional data which is also
              encrypted when it is sent to our servers. We do not share or sell
              your data to any including third parties, nor do we sell or share
              you data to potential advertisers.
            </p>
            <h3 className="bold">Aggregated Statistics</h3>
            <p>
              Medzcool may collect statistics about the behavior of visitors
              to its websites. Medzcool may display this information publicly
              or provide it to others. However, Medzcool does not disclose
              personally-identifying information other than as described below.
            </p>
            <h3 className="bold">
              Protection of Certain Personally-Identifying Information
            </h3>
            <p>
              {" "}
              Medzcool discloses potentially personally-identifying and
              personally-identifying information only to those of its employees,
              contractors and affiliated organizations that (i) need to know
              that information in order to process it on Medzcools behalf or
              to provide services available at Medzcool's websites, and (ii)
              that have agreed not to disclose it to others. Some of those
              employees, contractors and affiliated organizations may be located
              outside of your home country; by using Medzcool's websites, you
              consent to the transfer of such information to them. Medzcool
              will not rent or sell potentially personally-identifying and
              personally-identifying information to anyone. Other than to its
              employees, contractors and affiliated organizations, as described
              above, Medzcool discloses potentially personally-identifying and
              personally-identifying information only when required to do so by
              law, or when Medzcool believes in good faith that disclosure is
              reasonably necessary to protect the property or rights of
              Medzcool, third parties or the public at large. If you are a
              registered user of an Medzcool website and have supplied your
              email address, Medzcool may occasionally send you an email to
              tell you about new features, solicit your feedback, or just keep
              you up to date with whats going on with Medzcool and our
              products. We primarily use our various product blogs to
              communicate this type of information, so we expect to keep this
              type of email to a minimum. If you send us a request (for example
              via a support email or via one of our feedback mechanisms), we
              reserve the right to publish it in order to help us clarify or
              respond to your request or to help us support other users.
              Medzcool takes all measures reasonably necessary to protect
              against the unauthorized access, use, alteration or destruction of
              potentially personally-identifying and personally-identifying
              information.
            </p>
            <h3 className="bold">Cookies</h3>
            <p>
              {" "}
              A cookie is a string of information that a website stores on a
              visitors computer, and that the visitors browser provides to the
              website each time the visitor returns. Medzcool uses cookies to
              help Medzcool identify and track visitors, their usage of
              Medzcool website, and their website access preferences.
              Medzcool visitors who do not wish to have cookies placed on
              their computers should set their browsers to refuse cookies before
              using Medzcool's websites, with the drawback that certain
              features of Medzcool's websites may not function properly
              without the aid of cookies.
            </p>
            <h3 className="bold">Privacy Policy Changes</h3>
            <p>
              {" "}
              Although most changes are likely to be minor, Medzcool may
              change its Privacy Policy from time to time, and in Medzcool's
              sole discretion. Medzcool encourages visitors to frequently
              check this page for any changes to its Privacy Policy. Your
              continued use of this site after any change in this Privacy Policy
              will constitute your acceptance of such change.
            </p>

            <p className="note">
              This Privacy Policy was crafted from{" "}
              <a href="http://automattic.com/privacy/">
                Wordpress.com's version
              </a>
              , which is available under a Creative Commons Sharealike license.
            </p>
          </div>
        </div>
      </TermsContainer>
      <Footer />
    </Fragment>
  );
}

const TermsContainer = styled.div`
  font-family: futura-pt, sans-serif;
  padding: 50px 15px;
  margin-top: 75px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;
