import styled from "styled-components";
import { blue, grayscale } from "./Colors";

export const ButtonRegular = styled.button`
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background: ${blue.bright};
`;

export const BorderButton = styled.button`
  border: none;
  padding: 10px;
  background: none;
  border-radius: 4px;
  width: ${(props) => (props.setWidth ? props.setWidth : `100%`)};
  color: ${(props) => (props.color ? props.color : `${blue.bright}`)};
  border: ${(props) =>
    props.border ? `1px solid ${props.border}` : `1px solid ${blue.bright}`};
`;

export const SolidButton = styled.button`
  border: none;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  width: ${(props) => (props.setWidth ? props.setWidth : `100%`)};
  color: ${(props) => (props.color ? props.color : `white`)};
  background: ${(props) =>
    props.background ? props.background : `${blue.bright}`};

  &:disabled {
    color: ${grayscale.lightDark};
    background-color: ${grayscale.gray};
  }
`;
