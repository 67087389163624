import React from "react";

// Styles
import styled from "styled-components";
import { themeColors, fontFamily, fontWeight } from "../Utilities";

export default function GridDisplay(props) {
  return <GridContainer>{props.children}</GridContainer>;
}

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  @media (max-width: 1024px) {
    grid-column-gap: 25px;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
  }
  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
  }
`;
