import React from "react";
import { mixpanelToken } from "../../settings";

// DEPENDENCIES
import mixpanel from "mixpanel-browser";

// COMPONENTS
import Spinner from "../../Utilities/Spinner";
import { ServerError } from "../../Utilities";
import AppCard from "./OurAppCards";
import GridDisplay from "../GridDisplay";
import YoutubeEmbed from "../YoutubeEmbed";
import HomepageCollections from "../HomepageCollections";

// STYLES
import styled from "styled-components";
import { fontFamily, Title, LargeText, BodyCopy } from "../../Typography";
import { ContentBlock } from "../../Elements";

// DATA
import { useQuery, gql } from "@apollo/client";

mixpanel.init(mixpanelToken);

export default function OurCollections(props) {
  return (
    <OurAppsContainer>
      <AppsContainer>
        <div className="grid">
          <div className="grid-left">
            <Title>Our Collections</Title>
            <p>
              Collections are sets of related cases grouped together by
              pathology, category or theme.
            </p>
          </div>
          <div className="grid-right">
            <AppGrid>
              <AppCard
                title="Poisons &amp; Antidotes"
                collection
                collectionUrl="poisons"
                description="Overdoses, antidoes &amp; everything toxicology."
                logo="https://medzcool.s3-us-west-2.amazonaws.com/Rounds/Checkout-Thumbnail-Poisons.jpg"
                mixpanel={mixpanel}
              />
              <AppCard
                title="Cardiac Life Support"
                collection
                collectionUrl="cardiac-life-support"
                description="Cases based on the ACLS Algorithm."
                logo="https://medzcool.s3-us-west-2.amazonaws.com/Rounds/cardiac-arrest-square-thumbnail.jpg"
                mixpanel={mixpanel}
              />
              <AppCard
                title="Pediatrics"
                collection
                collectionUrl="pediatrics"
                description="Explore some common &amp; not-so-common pediatric cases."
                logo="https://medzcool.s3-us-west-2.amazonaws.com/Rounds/pediatrics_bear_small.jpg"
                mixpanel={mixpanel}
              />
              <AppCard
                title="Trauma"
                collection
                collectionUrl="trauma"
                description="Traumatic injuries from gunshot wounds to blunt force trauma."
                logo="https://medzcool-static.s3.amazonaws.com/media/media/Trauma_ekEnQI2.jpg"
                mixpanel={mixpanel}
              />
            </AppGrid>
          </div>
        </div>
      </AppsContainer>
    </OurAppsContainer>
  );
}
const OurAppsContainer = styled.div`
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  background: #f5f8ff;
`;

const AppsContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  padding: 75px 25px;
  max-width: 1300px;
  margin: auto;
  ${Title} {
    font-weight: 500;
    margin-bottom: 0;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 60px;
  }
  p {
    margin-top: 15px;
    font-size: 24px;
  }
  @media (max-width: 1000px) {
    padding: 75px 25px;
  }
  @media (max-width: 770px) {
    p {
      margin-top: 10px;
      font-size: 18px;
    }
    .grid {
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
  }
  @media (max-width: 450px) {
    padding: 50px 10px 75px 10px;
    p {
      font-size: 1.1em;
    }
    .grid {
      .grid-left {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
`;

const AppGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 770px) {
    /* grid-template-columns: 1fr 1fr; */
  }
  @media (max-width: 450px) {
    /* grid-template-columns: 1fr 1fr; */
    grid-gap: 10px;
  }
`;
