// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
import { loadStripe } from "@stripe/stripe-js";

// DEV/PROD TOGGLE
export const development = false;

// STRIPE KEYS
const stripKey = development
  ? "pk_test_Cy67gqjZIC3vQVFaI6aan6X3"
  : "pk_live_VEK0vCcloCuAbjcW4h4yQcaQ";
export const stripePromise = loadStripe(stripKey);

// URLS
export let url;
export let homeUrl;

// MIXPANEL
export let mixpanelToken;

if (development === true) {
  url = "http://127.0.0.1:8000";
  homeUrl = "https://localhost:3000";
  mixpanelToken = "9b04a0c3b7311d2eb446df05641f7b88";
} else {
  url = "https://api.medzcool.com";
  homeUrl = "https://medzcool.com";
  mixpanelToken = "53419f0f6fae3ecd6e4f27a3f34e1d95";
}