import React, { useState, useEffect } from "react";

// Settings
import { Breakpoints } from "../../DesignSettings.jsx";

// Components
import Spinner from "../../Utilities/Spinner";

// Styles
import styled from "styled-components";
import { SmallTitle, fontFamily, Title, MediumText } from "../../Typography";

// Dependencies
import { Redirect } from "react-router-dom";

// Styles
import { blue } from "../../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";

export default function OurAppCards(props) {
  const {
    title,
    logo,
    url,
    description,
    checkoutThumbnail,
    collectionTitle,
    collectionUrl,
    collection,
    mixpanel,
    comingSoon,
  } = props;

  const [redirectURL, setRedirectURL] = useState();
  useEffect(() => {
    // SET REDIRECT URL
    if (collection) {
      setRedirectURL(`https://rounds.medzcool.com/collection/` + collectionUrl);
    } else {
      setRedirectURL(url);
    }
  }, []);

  const [redirected, setRedirected] = useState(false);

  if (redirected && collection) {
    window.location.href =
      `https://rounds.medzcool.com/collection/` + collectionUrl;
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (redirected && comingSoon) return <ComingSoon>{comingSoon}</ComingSoon>;
  if (redirected && !comingSoon) return <Redirect push to={`/${title}`} />;

  return (
    <AppCardContainer
      onClick={() => {
        mixpanel.track("Homepage", {
          App: title,
          Action: "Clicked",
          "Click Action": collection ? "Our Collections" : "Our Apps",
        });
        setRedirected(true);
      }}>
      <img className="app-icon" src={logo || checkoutThumbnail} alt={title} />

      <div className="app-title">{title}</div>
      <MediumText className="description">{description}</MediumText>

      <CardCTA comingSoon={comingSoon && "true"}>
        <span>{comingSoon ? comingSoon : "Learn More"}</span>
        {!comingSoon && (
          <div className="icon">
            <FontAwesomeIcon icon={faArrowRight} size="sm" />
          </div>
        )}
      </CardCTA>
    </AppCardContainer>
  );
}
const ComingSoon = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  min-height: 150px;
  text-align: center;
`;
const AppCardContainer = styled.div`
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 8px 24px;
  border-radius: 24px;
  border: 1px solid #f2f2f2;
  max-width: 300px;
  position: relative;
  background: white;
  cursor: pointer;
  height: 240px;
  .app-icon {
    height: 75px;
    width: 75px;
    object-fit: cover;
    margin-bottom: 8px;
    border-radius: 50%;
  }
  .app-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .button-container {
    margin-top: 40px;
  }
  .app-card-cta {
  }
  @media (max-width: 768px) {
    max-width: none;
    width: auto;
  }
  @media (max-width: 450px) {
    padding: 10px;
    text-align: center;
    height: auto;
    min-height: 180px;
    .app-icon {
      height: 55px;
      width: 55px;
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
    .app-title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 3px;
    }
    .description {
      font-size: 14px;
    }
  }
  @media (max-width: 350px) {
    .app-icon {
      height: 48px;
      width: 48px;
    }
    .description {
      font-size: 13px;
    }
  }
`;
const CardCTA = styled.div`
  margin-top: 20px;
  position: absolute;
  bottom: 20px;
  span {
    margin-right: 5px;
  }
  .icon {
    display: inline-block;
  }
  color: ${(props) => (props.comingSoon ? "gray" : `${blue.bright}`)};
  @media (max-width: 450px) {
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 13px;
    bottom: 10px;
  }
`;
