import React from "react";

// Styles
import styled from "styled-components";
import { fontFamily } from "./Typography";

export default function Button(props) {
  const { active, padding } = props;
  return (
    <StyledButton active={active} padding={padding}>
      {props.buttonLabel}
      {props.children}
    </StyledButton>
  );
}

export const StyledButton = styled.button`
  font-family: ${fontFamily.sansSerif};
  border: none;
  padding: ${props => props.padding ? props.padding : `10px`};
  width: 100%;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  color: white;
  ${(props) =>
    props.active ? `background: #0071e3;` : `background: lightgray; `}
  @media(max-width:425px) {
      font-size: 14px;
  }
`;
