import React, { Fragment } from "react";

// Styles
import styled from "styled-components";
import Footer from "./Footer/Footer";

export default function Terms(props) {
  return (
    <Fragment>
      <TermsContainer>
        <h1>Terms of Use</h1>
        <div className="document">
          <p>
            The following terms and conditions govern all use of the Medzcool
            website and all content, services and products available at or
            through the website. The Website is owned and operated by Medzcool
            LLC (Medzcool). The Website is offered subject to your acceptance
            without modification of all of the terms and conditions contained
            herein and all other operating rules, policies (including, without
            limitation, <a href="/privacy/">Medzcool’s Privacy Policy</a>) and
            procedures that may be published from time to time on this Site by
            Medzcool (collectively, the Agreement).
          </p>
          <p>
            Please read this Agreement carefully before accessing or using the
            Website. By accessing or using any part of the web site, you agree
            to become bound by the terms and conditions of this agreement. If
            you do not agree to all the terms and conditions of this agreement,
            then you may not access the Website or use any services. If these
            terms and conditions are considered an offer by Medzcool, acceptance
            is expressly limited to these terms. The Website is available only
            to individuals who are at least 13 years old.
          </p>
          <ol>
            <li>
              <strong>Your Medzcool Account and Site.</strong> If you create an
              account on the Website, you are responsible for maintaining the
              security of your account and its content, and you are fully
              responsible for all activities that occur under the account and
              any other actions taken in connection with the Website. You must
              not describe or assign content to your account in a misleading or
              unlawful manner, including in a manner intended to trade on the
              name or reputation of others, and Medzcool may change or remove
              any description or keyword that it considers inappropriate or
              unlawful, or otherwise likely to cause Medzcool liability. You
              must immediately notify Medzcool of any unauthorized uses of your
              account or any other breaches of security. Medzcool will not be
              liable for any acts or omissions by You, including any damages of
              any kind incurred as a result of such acts or omissions.
            </li>
            <li>
              <strong>Responsibility of Contributors.</strong> If you operate an
              account, comment on a screenshot, post material to the Website,
              post links on the Website, or otherwise make (or allow any third
              party to make) material available by means of the Website (any
              such material, Content), You are entirely responsible for the
              content of, and any harm resulting from, that Content. That is the
              case regardless of whether the Content in question constitutes
              text or graphics. By making Content available, you represent and
              warrant that:
              <ul>
                <li>
                  the downloading, copying and use of the Content will not
                  infringe the proprietary rights, including but not limited to
                  the copyright, patent, trademark or trade secret rights, of
                  any third party;
                </li>
                <li>
                  if your employer has rights to intellectual property you
                  create, you have either (i) received permission from your
                  employer to post or make available the Content, including but
                  not limited to any software, or (ii) secured from your
                  employer a waiver as to all rights in or to the Content;
                </li>
                <li>
                  you have fully complied with any third-party licenses relating
                  to the Content, and have done all things necessary to
                  successfully pass through to end users any required terms;
                </li>
                <li>
                  the Content does not contain or install any viruses, worms,
                  malware, Trojan horses or other harmful or destructive
                  content;
                </li>
                <li className="important">
                  the Content is not spam, is not machine- or
                  randomly-generated, and does not contain unethical or unwanted
                  commercial content designed to drive traffic to third party
                  sites or boost the search engine rankings of third party
                  sites, or to further unlawful acts (such as phishing) or
                  mislead recipients as to the source of the material (such as
                  spoofing);
                </li>
                <li>
                  the Content is not obscene, libelous or defamatory, hateful or
                  racially or ethnically objectionable, and does not violate the
                  privacy or publicity rights of any third party;
                </li>
                <li>
                  your account is not getting advertised via unwanted electronic
                  messages such as spam links on newsgroups, email lists, other
                  blogs and web sites, and similar unsolicited promotional
                  methods;
                </li>
                <li>
                  your account is not named in a manner that misleads your
                  readers into thinking that you are another person or company.
                  For example, your account’s URL or name is not the name of a
                  person other than yourself or company other than your own; and
                </li>
                <li>
                  you have, in the case of Content that includes computer code,
                  accurately categorized and/or described the type, nature, uses
                  and effects of the materials, whether requested to do so by
                  Medzcool or otherwise.
                </li>
              </ul>
              <p>
                Medzcool reserves the right to remove any screenshot (or content
                created on Medzcool, e.g. comments, messages, avatars, etc.) for
                any reason whatsoever.
              </p>
              <p>
                Medzcool reserves the right to ban any member or website from
                using the service for any reason.
              </p>
              <p>
                By uploading your screenshots to Medzcool you give Medzcool
                permission to use or distribute your screenshots on Medzcool.com
                or affiliated sites.
              </p>
              <p>
                All screenshots uploaded are copyright © their respective
                owners.
              </p>
              <p>
                If you delete Content, Medzcool will use reasonable efforts to
                remove it from the Website, but you acknowledge that caching or
                references to the Content may not be made immediately
                unavailable.
              </p>
              <p>
                Without limiting any of those representations or warranties,
                Medzcool has the right (though not the obligation) to, in
                Medzcool’s sole discretion (i) refuse or remove any content
                that, in Medzcool’s reasonable opinion, violates any Medzcool
                policy or is in any way harmful or objectionable, or (ii)
                terminate or deny access to and use of the Website to any
                individual or entity for any reason, in Medzcool’s sole
                discretion. Medzcool will have no obligation to provide a refund
                of any amounts previously paid.
              </p>
            </li>
            <li>
              <strong>Responsibility of Website Visitors.</strong> Medzcool has
              not reviewed, and cannot review, all of the material posted to the
              Website, and cannot therefore be responsible for that materials
              content, use or effects. By operating the Website, Medzcool does
              not represent or imply that it endorses the material there posted,
              or that it believes such material to be accurate, useful or
              non-harmful. You are responsible for taking precautions as
              necessary to protect yourself and your computer systems from
              viruses, worms, Trojan horses, and other harmful or destructive
              content. The Website may contain content that is offensive,
              indecent, or otherwise objectionable, as well as content
              containing technical inaccuracies, typographical mistakes, and
              other errors. The Website may also contain material that violates
              the privacy or publicity rights, or infringes the intellectual
              property and other proprietary rights, of third parties, or the
              downloading, copying or use of which is subject to additional
              terms and conditions, stated or unstated. Medzcool disclaims any
              responsibility for any harm resulting from the use by visitors of
              the Website, or from any downloading by those visitors of content
              there posted.
            </li>
            <li>
              <strong>Content Posted on Other Websites.</strong> We have not
              reviewed, and cannot review, all of the material, including
              computer software, made available through the websites and
              webpages to which Medzcool links, and that link to Medzcool.
              Medzcool does not have any control over those non-Medzcool
              websites and webpages, and is not responsible for their contents
              or their use. By linking to a non-Medzcool website or webpage,
              Medzcool does not represent or imply that it endorses such website
              or webpage. You are responsible for taking precautions as
              necessary to protect yourself and your computer systems from
              viruses, worms, Trojan horses, and other harmful or destructive
              content. Medzcool disclaims any responsibility for any harm
              resulting from your use of non-Medzcool websites and webpages.
            </li>
            <li>
              <strong>Copyright Infringement.</strong> Medzcool has adopted the
              following policy toward copyright infringement on the Service in
              accordance with the Digital Millennium Copyright Act (a copy of
              which is located at
              http://www.loc.gov/copyright/legislation/dmca.pdf, the "DMCA"). If
              you believe that Content residing or accessible on or through the
              Service infringes a copyright, you may send a notice of copyright
              infringement containing the following information to the following
              email address:
              <div>Email: hello@medzcool.com</div>
              <ul>
                <li>Identification of the work or material being infringed.</li>
                <li>
                  Identification of the material that is claimed to be
                  infringing, including its location, with sufficient detail so
                  that Medzcool is capable of finding it and verifying its
                  existence.
                </li>
                <li>
                  Contact information for the notifying party (the "Notifying
                  Party"), including name, address, telephone number and email
                  address.
                </li>
                <li>
                  A statement that the Notifying Party has a good faith belief
                  that the material is not authorized by the copyright owner,
                  its agent or law.
                </li>
                <li>
                  A statement made under penalty of perjury that the information
                  provided in the notice is accurate and that the Notifying
                  Party is authorized to make the complaint on behalf of the
                  copyright owner.
                </li>
                <li>
                  A physical or electronic signature of a person authorized to
                  act on behalf of the owner of the copyright that has been
                  allegedly infringed.
                </li>
              </ul>
              Medzcool will respond to valid DMCA requests within 10 days. In
              all cases, if you do not hear a response from us within 10 days of
              submitting a complaint, please email us again at contact [at]
              Medzcool dot com confirm that we received your original complaint.
              As you may know, spam blockers sometimes reject important emails
              from unknown parties.
              <br /> Please note that under applicable law any person who
              knowingly materially misrepresents that material or activity is
              infringing may be subject to liability for damages.
              <br /> In an effort to be transparent in removing or restricting
              access to user-uploaded content, Medzcool may make public any DMCA
              notice received (with personal contact information removed). This
              may include posting the notice to a public-facing website, among
              other methods.
              <br /> After removing access to the material pursuant to a valid
              DMCA notice, Medzcool will immediately notify the user responsible
              for the allegedly infringing material that it has removed or
              disabled access to the material.
              <br /> Medzcool reserves the right, in its sole discretion, to
              immediately terminate the account of any member who is the subject
              of repeated DMCA notifications.
              <br />
              <strong>Submitting a DMCA Counter-Notification:</strong>
              <br /> If you believe you are the wrongful subject of a DMCA
              notification, you may file a counter-notification with Medzcool by
              providing the following information to the Designated Agent at the
              address above:
              <br />
              <ul>
                <li>
                  The specific URLs of material that Medzcool has removed or to
                  which Medzcool has disabled access.
                </li>
                <li>
                  Your name, address, telephone number, and email address.
                </li>
                <li>
                  A statement that you consent to the jurisdiction of Federal
                  District Court for the judicial district in which your address
                  is located (or the federal district courts located in Essex
                  County, Massachusetts if your address is outside of the United
                  States), and that you will accept service of process from the
                  person who provided the original DMCA notification or an agent
                  of such person.
                </li>
                <li>
                  The following statement: "I swear, under penalty of perjury,
                  that I have a good faith belief that the material was removed
                  or disabled as a result of a mistake or misidentification of
                  the material to be removed or disabled."
                </li>
                <li>Your signature.</li>
              </ul>
              Upon receipt of a valid counter-notification, Medzcool will
              forward it to Notifying Party who submitted the original DMCA
              notification. The original Notifying Party (or the copyright
              holder he or she represents) will then have ten (10) days to
              notify us that he or she has filed legal action relating to the
              allegedly infringing material. If Medzcool does not receive any
              such notification within ten (10) days, we may restore the
              material to the Services.
            </li>
            <li>
              <strong>Intellectual Property.</strong> This Agreement does not
              transfer from Medzcool to you any Medzcool or third party
              intellectual property, and all right, title and interest in and to
              such property will remain (as between the parties) solely with
              Medzcool. Medzcool, the Medzcool logo, and all other trademarks,
              service marks, graphics and logos used in connection with
              Medzcool, or the Website are trademarks or registered trademarks
              of Medzcool or Medzcool's licensors. Other trademarks, service
              marks, graphics and logos used in connection with the Website may
              be the trademarks of other third parties. Your use of the Website
              grants you no right or license to reproduce or otherwise use any
              Medzcool or third-party trademarks.
            </li>
            <li>
              <strong>Changes. </strong>Medzcool reserves the right, at its sole
              discretion, to modify or replace any part of this Agreement. It is
              your responsibility to check this Agreement periodically for
              changes. Your continued use of or access to the Website following
              the posting of any changes to this Agreement constitutes
              acceptance of those changes. Medzcool may also, in the future,
              offer new services and/or features through the Website (including,
              the release of new tools and resources). Such new features and/or
              services shall be subject to the terms and conditions of this
              Agreement.{" "}
              <strong>
                <br />
              </strong>
            </li>
            <li>
              <strong>Termination. </strong>Medzcool may terminate your access
              to all or any part of the Website at any time, with or without
              cause, with or without notice, effective immediately. If you wish
              to terminate this Agreement or your Medzcool account (if you have
              one), you may simply discontinue using the Website.
              Notwithstanding the foregoing, if you have a VIP Services account,
              such account can only be terminated by Medzcool if you materially
              breach this Agreement and fail to cure such breach within thirty
              (30) days from Medzcool's notice to you thereof; provided that,
              Medzcool can terminate the Website immediately as part of a
              general shut down of our service. All provisions of this Agreement
              which by their nature should survive termination shall survive
              termination, including, without limitation, ownership provisions,
              warranty disclaimers, indemnity and limitations of liability.{" "}
              <strong>
                <br />
              </strong>
            </li>
            <li className="important">
              <strong>Disclaimer of Warranties.</strong> The Website is provided
              “as is”. Medzcool and its suppliers and licensors hereby disclaim
              all warranties of any kind, express or implied, including, without
              limitation, the warranties of merchantability, fitness for a
              particular purpose and non-infringement. Neither Medzcool nor its
              suppliers and licensors, makes any warranty that the Website will
              be error free or that access thereto will be continuous or
              uninterrupted. You understand that you download from, or otherwise
              obtain content or services through, the Website at your own
              discretion and risk.
            </li>
            <li className="important">
              <strong>Limitation of Liability.</strong> In no event will
              Medzcool, or its suppliers or licensors, be liable with respect to
              any subject matter of this agreement under any contract,
              negligence, strict liability or other legal or equitable theory
              for: (i) any special, incidental or consequential damages; (ii)
              the cost of procurement or substitute products or services; (iii)
              for interuption of use or loss or corruption of data; or (iv) for
              any amounts that exceed the fees paid by you to Medzcool under
              this agreement during the twelve (12) month period prior to the
              cause of action. Medzcool shall have no liability for any failure
              or delay due to matters beyond their reasonable control. The
              foregoing shall not apply to the extent prohibited by applicable
              law.
            </li>
            <li>
              <strong>General Representation and Warranty.</strong> You
              represent and warrant that (i) your use of the Website will be in
              strict accordance with the Medzcool Privacy Policy, with this
              Agreement and with all applicable laws and regulations (including
              without limitation any local laws or regulations in your country,
              state, city, or other governmental area, regarding online conduct
              and acceptable content, and including all applicable laws
              regarding the transmission of technical data exported from the
              United States or the country in which you reside) and (ii) your
              use of the Website will not infringe or misappropriate the
              intellectual property rights of any third party.
            </li>
            <li>
              <strong>Indemnification.</strong> You agree to indemnify and hold
              harmless Medzcool, its contractors, and its licensors, and their
              respective directors, officers, employees and agents from and
              against any and all claims and expenses, including attorneys fees,
              arising out of your use of the Website, including but not limited
              to out of your violation this Agreement.
            </li>
            <li className="last">
              <strong>Miscellaneous.</strong> This Agreement constitutes the
              entire agreement between Medzcool and you concerning the subject
              matter hereof, and they may only be modified by a written
              amendment signed by an authorized executive of Medzcool, or by the
              posting by Medzcool of a revised version. Except to the extent
              applicable law, if any, provides otherwise, this Agreement, any
              access to or use of the Website will be governed by the laws of
              the state of Massachusetts, U.S.A.
            </li>
          </ol>

          <p className="note">
            This Terms of Service was crafted from{" "}
            <a href="http://wordpress.com/tos/">Wordpress.com’s version</a>,
            which is available under{" "}
            <a href="http://creativecommons.org/licenses/by-sa/4.0/">
              a Creative Commons ShareAlike license
            </a>
            .
          </p>
        </div>
      </TermsContainer>
      <Footer />
    </Fragment>
  );
}

const TermsContainer = styled.div`
  font-family: futura-pt, sans-serif;
  padding: 50px 15px;
  margin-top: 75px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;
