import styled from "styled-components";
import { fontFamily } from "../Typography";

export const ErrorMessage = styled.div`
  padding: 5px 15px;
  font-family: ${fontFamily.sansSerif};
  background-color: ${(props) =>
    props.errorType === "warning"
      ? `rgba(255, 222, 104, 0.5)`
      : `rgba(255, 104, 104, 0.5)`};
`;
