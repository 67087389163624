import React from "react";

// Styles
import styled from "styled-components";
import { fontFamily } from "./Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkullCrossbones } from "@fortawesome/pro-duotone-svg-icons";

export default function ClinicPromoContent(props) {
  return (
    <NoMatchContainer>
        <FontAwesomeIcon icon={faSkullCrossbones} size="6x"/>
        <h1>404</h1>
        <p>This page does not exist</p>
    </NoMatchContainer>
  );
}

const NoMatchContainer = styled.div`
  margin: auto;
  padding: 15px;
  margin-top:50px;
  max-width: 800px;
  font-family: ${fontFamily.sansSerif};
  text-align: center;
  h1 {
      margin-bottom: 5px;
  }
  p {
      margin-top: 5px;
  }
`;
