import React from "react";
import { mixpanelToken } from "../settings";

// DEPENDENCIES
import mixpanel from "mixpanel-browser";

// COMPONENTS
import Spinner from "../Utilities/Spinner";
import { ServerError } from "../Utilities";
import AppCard from "./OnboardingAppCard";
import GridDisplay from "../Homepage/GridDisplay";
import YoutubeEmbed from "../Homepage/YoutubeEmbed";
import HomepageCollections from "../Homepage/HomepageCollections";

// STYLES
import styled from "styled-components";
import { fontFamily, Title, LargeText, BodyCopy } from "../Typography";
import { ContentBlock } from "../Elements";

// DATA
import { useQuery, gql } from "@apollo/client";

const APPS = gql`
  query GET_APPS {
    apps {
      id
      title
      description
      url
      logo
    }
  }
`;

mixpanel.init(mixpanelToken);

export default function OnboardingApps(props) {
  const { loading, error, data } = useQuery(APPS);
  if (loading) return <Spinner />;
  if (error) return <ServerError error={error} />;
  const apps = data.apps;

  return (
    <AppsContainer>
      <ContentBlock>
        <TopSubTitle style={{ lineHeight: "120%" }}>
          Welcome to Medzcool.{" "}
          <span style={{ color: "#c9c9c9" }}>
            {" "}
            We make software, videos, and tools for medical education.
          </span>
        </TopSubTitle>
      </ContentBlock>
      <ContentBlock>
        <Title>Our Apps</Title>
        <p>Interactive software for medical education</p>
        <AppGrid>
          <AppCard
            title="Clinic"
            url="https://clinic.medzcool.com"
            description="A simulated medical clinic, where new patient encounters are added to your digital waiting room every week"
            logo="https://medzcool.s3-us-west-2.amazonaws.com/clinic/clinic-icon-sm.png"
            mixpanel={mixpanel}
          />
          {apps.map((app) => {
            return <AppCard key={app.id} {...app} mixpanel={mixpanel} />;
          })}
        </AppGrid>
      </ContentBlock>

      <ContentBlock style={{ marginTop: 120, marginBottom: 135 }}>
        <Title>Our Videos</Title>
        <p>
          Visit our channel to{" "}
          <a
            href="https://youtube.com/medzcool"
            onClick={() => {
              mixpanel.track("Onboarding", {
                Action: "See More Videos",
                "Onboarding Location": "Welcome",
              });
            }}>
            see more videos
          </a>
        </p>
        <GridDisplay>
          <YoutubeEmbed youtubeId={"wyNZkEmhZjI"} />
          <YoutubeEmbed youtubeId={"s-vT-6oKW2Q"} />
          <YoutubeEmbed youtubeId={"QStk4uUQ7fU"} />
          <YoutubeEmbed youtubeId={"anPLauNThNk"} />
          <YoutubeEmbed youtubeId={"Pao8171B354"} />
          <YoutubeEmbed youtubeId={"pZWBK8u7wVw"} />
        </GridDisplay>
      </ContentBlock>

      <HomepageCollections mixpanel={mixpanel} />
    </AppsContainer>
  );
}
const TopSubTitle = styled.h3`
  font-size: 40px;
  font-family: ${fontFamily.sansSerif};
  font-weight: 700;
  margin: auto;
  margin-bottom: 100px;
  margin-top: 75px;
  max-width: 800px;
  @media (max-width: 450px) {
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;
const AppsContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  padding-top: 75px;
  max-width: 1300px;
  margin: auto;
  margin-bottom: 150px;
  h2 {
    margin-bottom: 0;
  }
  p {
    margin-top: 0;
    font-size: 1.3em;
  }
  @media (max-width: 450px) {
    p {
      font-size: 1.1em;
    }
  }
`;

const AppGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;
