import styled from "styled-components";

// TypeKit ID: yim1brv

export const fontFamily = {
  sansSerif: "futura-pt, sans-serif",
  serif: "adobe-garamond-pro, serif",
};

export const fontWeight = {
  light: 300,
  regular: 400,
  heavy: 500,
  bold: 700,
};

export const BaseType = styled.div`
  max-width: 1300px;
  margin: auto;
  margin-bottom: 100px;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 20px;
  line-height: 140%;
  font-family: ${fontFamily.sansSerif};
  box-sizing: border-box;
  .serif {
    font-family: ${fontFamily.serif};
  }
  .sans-serif {
    font-family: ${fontFamily.sansSerif};
  }
  h1 {
    font-size: 2.2em;
  }
  h2 {
    font-size: 1.6em;
  }
  h3 {
    font-size: 1.3em;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
  @media (max-width: 425px) {
    font-size: 16px;
    padding-left: 5px;
    padding-right: 5px;
  }
`;
