import React, { Fragment, useState } from "react";

// Dependencies
import { Redirect } from "react-router-dom";
import landingImg from "../static/homepage-jumbotron.png";

// Components
import Button from "../Utilities/Button";

// Styles
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { BodyCopy, fontFamily, fontWeight } from "../Typography";

export default function HomepageLandingForEncounters(props) {
  const { user } = props;
  const [email, setEmail] = useState("");
  function handleEmail(e) {
    setEmail(e.target.value);
  }

  const [landingFormSubmitted, setLandingFormSubmitted] = useState(false);
  function handleSubmit(e, email) {
    e.preventDefault();
    setLandingFormSubmitted(true);
    return;
  }

  if (landingFormSubmitted)
    return (
      <Redirect
        homepageEmail={email}
        to={{
          pathname: "/register",
          state: { email: email, encounterExit: true },
        }}
      />
    );

  return (
    <Fragment>
      <HomepageLandingContainer>
        <div className="landing-img">
          <img src={landingImg} alt="digital tools" />
        </div>
        <div className="landing-text">
          <h2>Medical Cases &amp; Encounters</h2>
          <div className="landing-description">
            Medzcool makes interactive medical software like multiple-choice
            medical encounters.
          </div>
          {!user && (
            <FormContainer>
              <div className="landing-form">
                <form onSubmit={(e) => handleSubmit(e, email)}>
                  <input
                    type="text"
                    value={email}
                    placeholder="hello@medzcool.com"
                    onChange={handleEmail}
                  />
                  <Button
                    buttonLabel="Sign Up"
                    active
                    padding="20px"
                    onClick={(e) => handleSubmit(e, email)}
                    type="submit"
                    className="landing-button"
                  />
                </form>
              </div>
            </FormContainer>
          )}
        </div>
      </HomepageLandingContainer>
    </Fragment>
  );
}

const HomepageLandingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 65px;
  position: relative;
  font-family: ${fontFamily.sansSerif};
  max-width: 1050px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  /* margin-bottom: 5px; */
  padding: 15px 25px 50px 25px;
  .landing-img {
    img {
      width: 100%;
    }
  }
  .landing-text {
    justify-self: center;
    align-self: center;
    h2 {
      font-size: 65px;
      font-weight: 500;
      margin-bottom: 25px;
      line-height: 120%;
    }
    .landing-description {
      font-size: 24px;
    }
  }
  @media (max-width: 1000px) {
    .landing-text {
      h2 {
        font-size: 45px;
        margin-bottom: 15px;
      }
    }
  }
  @media (max-width: 770px) {
    padding: 100px 50px 100px 50px;
    display: block;
    .landing-img {
      display: none;
    }
  }
  @media (max-width: 560px) {
    padding: 40px 25px 75px 25px;
    .landing-text {
      h2 {
        font-size: 35px;
        margin-bottom: 10px;
      }
      .landing-description {
        font-size: 20px;
      }
    }
  }
`;

const FormContainer = styled.div`
  margin-top: 50px;
  form {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 15px;
  }
  input {
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    border-radius: 3px;
    border: lightgray 1px solid;
    font-size: 16px;
    font-family: ${fontFamily.sansSerif};
  }
  .landing-button {
    border-radius: 3px;
    font-weight: 500;
  }
  @media (max-width: 560px) {
    margin-top: 35px;
    form {
      grid-gap: 8px;
      grid-template-columns: 1fr;
    }
  }
`;
