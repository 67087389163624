import React from "react";

// Dependencies

//Components
import { ContentBlock } from "../../Elements";
import { DashboardSubHeading, LargeText } from "../../Typography";
import AppCard from "../AppCard";

// Styles

export default function AppDashboard(props) {
  const { user, mixpanel } = props;

  if (
    user.profile.subscribedApps.length > 0 ||
    user.profile.subscriptionStatus == "active"
  ) {
    return (
      <ContentBlock>
        <DashboardSubHeading>Your Apps</DashboardSubHeading>
        <div className="subscription-grid">
          {user.profile.subscriptionStatus == "active" && (
            <>
              <AppCard
                title="Clinic"
                url="https://clinic.medzcool.com"
                description="A simulated medical clinic, where new patient encounters are added to your digital waiting room every week"
                logo="https://medzcool.s3-us-west-2.amazonaws.com/clinic/clinic-icon-sm.png"
                mixpanel={mixpanel}
              />
              <AppCard
                title="Personas"
                url="https://personas.medzcool.com"
                description="Patient encounters powered by Artificial Intelligence. (Experimental)"
                logo="https://medzcool.s3.us-west-2.amazonaws.com/personas-logo.jpg"
                mixpanel={mixpanel}
              />
            </>
          )}
          {user.profile.subscribedApps.length > 0 && (
            <>
              {user.profile.subscribedApps.map((app) => {
                return <AppCard key={app.id} {...app} mixpanel={mixpanel} />;
              })}
            </>
          )}
        </div>
      </ContentBlock>
    );
  }

  return (
    <ContentBlock>
      <DashboardSubHeading>Your Apps</DashboardSubHeading>
      <div className="no-subscriptions">
        <LargeText>
          <p>You have not subscribed to any apps.</p>
        </LargeText>
      </div>
    </ContentBlock>
  );
}
