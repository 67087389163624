import React, { Fragment, useState, useEffect } from "react";
import { homeUrl } from "../settings";

// Components
import { ContentBlock, ContentLabel, Line } from "../Elements";

// Typography
import { Title, AnchorTag, BodyCopy, SmallTitle } from "../Typography";

// Styles
import styled from "styled-components";
import { themeColors, fontFamily, fontWeight, blue } from "../Utilities";

// Dependencies
import { Link } from "react-router-dom";

export default function Cranial(props) {
  const appDetails = {
    title: "Cranial",
  };

  const { user, mixpanel } = props;

  const [userHasApp, setUserHasApp] = useState(false);

  useEffect(() => {
    if (props.user) {
      props.user.profile.subscribedApps.map((app) => {
        if (app.title == appDetails.title) {
          setUserHasApp(true);
        }
      });
    }
  }, []);

  if (userHasApp) return null;
  return (
    <Fragment>
      <ContentBlock style={{ marginRight: 0, marginLeft: 0 }}>
        <CranialAppContainer>
          <div className="heading">
            <div className="desc">
              <div className="heading-text">
                <Title>Cranial</Title>
                <BodyCopy>
                  The most engaging way to learn the cranial nerves through
                  interactive content, quizzes and real-world clinical cases.
                </BodyCopy>
                <a
                  href={`${homeUrl}/cranial`}
                  className="learn-more"
                  onClick={() => {
                    mixpanel.track("Homepage", {
                      App: "Cranial",
                      Action: "Clicked",
                      "Click Action": "Learn More",
                    });
                  }}>
                  Learn More
                </a>
              </div>
              <img
                src="https://medzcool.s3-us-west-2.amazonaws.com/cranial/Cranial-landing.jpg"
                className="desktop"
              />
              <img
                src="https://medzcool.s3-us-west-2.amazonaws.com/cranial/Cranial-landing-mobile.jpg"
                className="mobile"
              />
            </div>
          </div>
        </CranialAppContainer>
      </ContentBlock>
    </Fragment>
  );
}

const CranialAppContainer = styled.div`
  .heading {
    padding: 25px 0 50px 0;
    .title {
      font-weight: 700;
      font-family: ${fontFamily.sansSerif};
      font-size: 36px;
      margin-bottom: 20px;
    }
    .desc {
      position: relative;
      display: grid;
      .heading-text {
        color: white;
        position: absolute;
        font-family: ${fontFamily.sansSerif};
        align-self: center;
        padding: 50px;
        max-width: 40%;
        .new {
          font-weight: 300;
          margin-bottom: 15px;
        }
        .learn-more {
          text-decoration: none;
          width: fit-content;
          padding: 10px 50px;
          margin-top: 35px;
          display: block;
          border: none;
          cursor: pointer;
          font-size: 16px;
          background: ${blue.bright};
          color: white;
        }
      }
      img {
        width: 100%;
        &.mobile {
          display: none;
        }
      }
      .subtitle {
        font-size: 18px;
        p {
          margin: 5px 0 0;
        }
        .beta {
          margin-top: 50px;
          display: grid;
          grid-template-columns: min-content auto;
          grid-gap: 15px;
          align-items: center;
          color: ${blue.bright};
          font-size: 13px;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .heading {
      .desc {
        img {
          &.mobile {
            display: block;
          }
          &.desktop {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .heading {
      .desc {
        grid-template-columns: 1fr;
        grid-gap: 0px;
      }
    }
  }
  @media (max-width: 425px) {
    .heading {
      padding: 15px 0 25px 0;
      .desc {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 15px;
        .heading-text {
          max-width: 60%;
          padding: 10px;
        }
      }
    }
  }
`;
