import React from "react";

// Styles
import styled from "styled-components";
import { fontFamily, TopLabel, Title, BodyCopy } from "../Typography";
import {
  ContentBlock,
  Line,
  FeaturedBlock,
  ContentLabelContainer
} from "../Elements";
import { blue } from "../Utilities";

// Dependencies
import { Link } from "react-router-dom";

export default function ClinicalRoundsBanner(props) {
  return (
    <ContentBlock style={{marginBottom: "25px"}}>
      <ClinicalRoundsBannerContainer>
        <div className="desc-rounds">
          <div className="heading-text">
            <TopLabel>Multiple Choice Questions</TopLabel>
            <Title>Clinical Cases</Title>
            <BodyCopy style={{ maxWidth: 550 }}>
              Clinical case scenarios presented as multiple choice questions.
            </BodyCopy>

          </div>
          <img src="https://medzcool.s3-us-west-2.amazonaws.com/Rounds/clinical-isometric.jpg" />
        </div>
      </ClinicalRoundsBannerContainer>
    </ContentBlock>
  );
}

const ClinicalRoundsBannerContainer = styled.div`
  .desc-rounds {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    .heading-text {
      font-family: ${fontFamily.sansSerif};
      align-self: center;
      .new {
        font-weight: 300;
        margin-bottom: 15px;
      }
      .learn-more {
        text-decoration: none;
        width: fit-content;
        padding: 15px 50px;
        margin-top: 35px;
        display: block;
        border: none;
        cursor: pointer;
        font-size: 16px;
        background: ${blue.bright};
        color: white;
      }
    }
    img {
      width: 100%;
    }
    .subtitle {
      font-size: 18px;
      p {
        margin: 5px 0 0;
      }
      .beta {
        margin-top: 50px;
        display: grid;
        grid-template-columns: min-content auto;
        grid-gap: 15px;
        align-items: center;
        color: ${blue.bright};
        font-size: 13px;
      }
    }
  }
  @media (max-width: 768px) {
    .desc-rounds {
      grid-template-columns: 1fr;
      grid-gap: 0px;
      .subtitle {
        font-size: 16px;
      }
      img {
        display: none;
      }
    }
  }
  @media (max-width: 425px) {
    .desc-rounds {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }
  }
`;
