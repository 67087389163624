import React, { Fragment, useEffect } from "react";

// Components
import AppCard from "../AppCard";
import CollectionDashboard from "../../Rounds/CollectionDashboard";
import AppDashboard from "./AppDashboard";

// Styles
import styled from "styled-components";
import { ContentBlock, Line } from "../../Elements";
import {
  fontFamily,
  Title,
  DashboardSubHeading,
  LargeText,
} from "../../Typography";
import { ErrorMessage } from "../../Utilities/ErrorMessage";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

// Dependencies
import { Link } from "react-router-dom";

export default function Dashboard(props) {
  const { user, mixpanel } = props;

  return (
    <DashboardContainer>
      {props.user && (
        <Fragment>
          <Title style={{ marginTop: 65, marginLeft: 15, marginRight: 15 }}>
            Dashboard
          </Title>
          {user.profile.subscriptionStatus === "past_due" && (
            <ContentBlock>
              <ErrorMessage style={{ marginBottom: 50 }}>
                There was a problem processing your last invoice. Please{" "}
                <Link to="/settings">update your payment settings.</Link>
              </ErrorMessage>
            </ContentBlock>
          )}

          <AppDashboard {...props} mixpanel={mixpanel} />

          <ContentBlock>
            <DashboardSubHeading>Your Case Collections</DashboardSubHeading>
            <CollectionDashboard
              user={user}
              AppCard={AppCard}
              mixpanel={mixpanel}
            />
          </ContentBlock>
        </Fragment>
      )}
    </DashboardContainer>
  );
}
const DashboardContainer = styled.div`
  font-family: ${fontFamily.sansSerif};
  .no-subscriptions {
    background: #f5f5f5;
    padding: 50px;
    text-align: center;
    a {
      text-decoration: none;
      color: #062bba;
    }
  }
  .subscription-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
  }
  @media (max-width: 1024px) {
    .subscription-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  @media (max-width: 768px) {
    .subscription-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media (max-width: 425px) {
    .subscription-grid {
      grid-template-columns: 1fr;
    }
    .no-subscriptions {
      padding: 50px 15px;
      p,
      a {
        font-size: 16px;
        margin: 0px;
      }
    }
  }
`;
