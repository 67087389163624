import styled from "styled-components";

export const fontFamily = {
  sansSerif: "futura-pt, sans-serif",
};

export const fontWeight = {
  bold: "700",
  regular: "400",
  light: "300",
};

const bodyFontSize = {
  desktop: "20px",
  mobile: "18px",
};

const blue = {
  bright: "#0071e3",
  neutral: "#3b5998"
};


export const TopLabel = styled.div`
  font-weight: ${fontWeight.light};
  font-size: 16px;
`;

export const Title = styled.h2`
  font-size: 34px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.bold};
  margin: 0;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 425px) {
    font-size: 28px;
  }
`;

export const DashboardSubHeading = styled.h3`
  font-size: 28px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.bold};
  margin: 0;
  margin-bottom: 15px;
  @media (max-width: 768px) {
    font-size: 26px;
  }
  @media (max-width: 425px) {
    font-size: 24px;
  }
`;

export const BodyCopy = styled.div`
  font-size: 20px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.regular};
  margin-bottom: 5px;
  margin-top: 5px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const SmallTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 8px;
  font-family: ${fontFamily.sansSerif};
  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const SubTitle = styled.h3`
  font-size: 20px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.bold};
  margin-bottom: 5px;
  @media (max-width: 425px) {
    font-size: 1.1em;
  }
`;

export const LargeText = styled.div`
  font-size: 1.4em;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.regular};
  @media (max-width: 425px) {
    font-size: 1.2em;
  }
`;


export const MediumText = styled.div`
  font-size: 16px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.regular};
  @media (max-width: 425px) {
    font-size: 1.1em;
  }
`;

export const SmallText = styled.div`
  font-size: 14px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.regular};
`;

export const AnchorTag = styled.a`
  text-decoration: none;
  ${(props) =>
    props.color ? `color: ${props.color}` : `color: ${blue.bright}`}
`;

export const AnchorStyle = styled.span`
  text-decoration: underline;
  color: ${blue.bright};
  cursor: pointer;
  font-size: ${(props) => props.fontSizeDesktop && props.fontSizeDesktop};
  @media (max-width: 768px) {
    font-size: ${(props) => props.fontSizeMobile && `${bodyFontSize.mobile}`};
  }
`;

export const Logo = styled.div`
  font-family: ${fontFamily.sansSerif};
  color: white;
  font-weight: ${fontWeight.bold}
  font-size: 22px;
`;
