import React, { useState, Fragment } from "react";
import { useSpring, animated } from "react-spring";

// Components
import Menu from "./Menu";

// Dependencies
import { Link } from "react-router-dom";
import { mixpanelToken } from "../settings";

// Styles
import styled from "styled-components";
import { fontFamily, blue } from "../Utilities";
import { Logo } from "../Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-regular-svg-icons";

// Static
import mobileLogo from "../static/medzcool-logo.png";

import { useMutation, gql } from "@apollo/client";

const mixpanel = require("mixpanel-browser");
// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
mixpanel.init(mixpanelToken);

const LOGOUT = gql`
  mutation logout {
    logout {
      user {
        id
      }
    }
  }
`;

export default function Header(props) {
  const user = props.user;

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }
  const menuAnimation = useSpring({
    transform: isMenuOpen ? `translate3d(0, 0, 0)` : `translate3d(100%, 0, 0)`,
  });
  const menuOpacity = useSpring({
    opacity: isMenuOpen ? 0.5 : 0,
    display: isMenuOpen ? `block` : `none`,
  });
  const [logout] = useMutation(LOGOUT, {
    onCompleted: (data) => {
      mixpanel.reset();
    },
  });

  if (!user) {
    return (
      <HeaderContainer>
        <HeaderInnerContainer>
          <HeaderLeft>
            <Logo>
              <Link to="/">
                <img src={mobileLogo} className="mobile-icon logo-icon" />
              </Link>

              <span className="logo-text">
                <Link to="/">MEDZCOOL</Link>
              </span>
            </Logo>
          </HeaderLeft>
          <HeaderRight>
            <div className="desktop">
              <span className="menu-item">
                <Link to="/login">LOGIN</Link>
              </span>
              <span className="menu-item sign-up">
                <Link to="/register">SIGN UP</Link>
              </span>
            </div>
          </HeaderRight>
        </HeaderInnerContainer>
      </HeaderContainer>
    );
  } else {
    return (
      <HeaderContainer>
        <HeaderInnerContainer>
          <HeaderLeft>
            <Logo>
              <Link to="/">
                <img src={mobileLogo} className="logo-icon" />
              </Link>
            </Logo>
          </HeaderLeft>
          <HeaderRight>
            <Fragment>
              <Menu
                style={menuAnimation}
                toggleMenu={toggleMenu}
                opacity={menuOpacity}
                logout={logout}
                client={props.client}
                {...props}
              />
            </Fragment>
            <a href="https://medzcool.com" className="menu-item desktop">
              DASHBOARD
            </a>
            <a href="https://rounds.medzcool.com" className="menu-item desktop">
              COLLECTIONS
            </a>
            <a href="https://clinic.medzcool.com" className="menu-item desktop">
              CLINIC
            </a>
            <div
              className="menu-item"
              onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <FontAwesomeIcon icon={faBars} size="lg" />
            </div>
          </HeaderRight>
        </HeaderInnerContainer>
      </HeaderContainer>
    );
  }
}

const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 50px;
  background-color: white;
  border-bottom: 1px solid #d8d8d8;
  a {
    text-decoration: none;
    color: black;
  }
`;
const HeaderInnerContainer = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  max-width: 1300px;
  margin: auto;
  @media (max-width: 768px) {
    grid-template-columns: max-content 1fr;
  }
`;
const HeaderRight = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-self: end;
  align-items: center;
  margin-right: 15px;
  font-family: ${fontFamily.sansSerif};
  .menu-item {
    cursor: pointer;
    margin-left: 40px;
    font-weight: 500;
    &.sign-up {
      a {
        color: white;
        background: ${blue.bright};
        padding: 5px 10px;
        border-radius: 5px;
      }
    }
  }
  @media (max-width: 768px) {
    .menu-item {
      margin-left: 25px;
      &.desktop {
        display: none;
      }
    }
  }
`;
const HeaderLeft = styled.div`
  color: black;
  margin-left: 15px;
  .mobile-icon {
    display: none;
  }
  .logo-icon {
    height: 20px;
  }
  .logo-text {
    font-weight: 700;
    font-size: 20px;
    a {
      text-decoration: none;
      color: black;
    }
  }
  .menu-item {
    font-family: ${fontFamily.sansSerif};
    color: black;
    cursor: pointer;
    a {
      text-decoration: none;
    }
  }
  .mobile {
    display: none;
  }
  @media (max-width: 768px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
  @media (max-width: 425px) {
    .logo-icon {
      display: block;
    }
    .logo-text {
      display: none;
    }
  }
`;
