import styled from "styled-components";
import { fontFamily, fontWeight } from "../Utilities";
import { MediumText, BodyCopy } from "../Typography";

export const ContentBlock = styled.div`
  margin: 50px 15px 65px 15px;
`;

export const FeaturedBlock = styled.div`
  margin: 50px 15px 65px 15px;
  @media (max-width: 1024px) {
    margin: 50px 15px 50px 15px;
  }
`;

export const ContentLabelContainer = styled.div`
  margin: 0;
  margin-bottom: 35px;
  @media (max-width: 425px) {
    margin-bottom: 25px;
  }
`;

export const ContentLabel = styled.h2`
  font-size: 28px;
  font-family: ${fontFamily.sansSerif};
  font-weight: ${fontWeight.bold};
  margin-bottom:0;
`;

export const ContentDescription = styled(BodyCopy)`
  color: gray;
`;

export const Line = styled.div`
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0 15px;
`;
