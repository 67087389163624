import React, { useState, useEffect } from "react";
import { homeUrl } from "../settings";

// Components
import { ContentBlock, ContentLabel } from "../Elements";

// Typography
import { Title, AnchorTag, BodyCopy, SmallTitle } from "../Typography";

// Styles
import styled from "styled-components";
import { themeColors, fontFamily, fontWeight, blue } from "../Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";

// Dependencies
import { Link } from "react-router-dom";

export default function FeaturedAppAlt(props) {
  const appDetails = {
    imgSrcProp:
      "https://s3-us-west-2.amazonaws.com/medzcool/medzcool-main-app/images/auscultate-banner-3.jpg",
    imgAltProp: "Auscultate",
    title: "Auscultate",
    description:
      "An interactive medical sound library to help you learn the physical exam skill of auscultation. Lung and Heart sounds on-demand, quizes and high-yield graphics all at your fingerstips so you can learn, reinforce and discover new skills to become a better clinician.",
    link: `${homeUrl}/auscultate`,
    category: "Auscultate",
  };

  const { user, mixpanel } = props;

  const [userHasApp, setUserHasApp] = useState(false);

  useEffect(() => {
    if (props.user) {
      props.user.profile.subscribedApps.map((app) => {
        if (app.title == appDetails.title) {
          setUserHasApp(true);
        }
      });
    }
  }, []);

  if (userHasApp) return null;
  return (
    <ContentBlock style={{ marginRight: 0, marginLeft: 0 }}>
      <FeaturedAppContainer>
        <div className="desktop">
          <div
            className="featured-label"
            style={{ color: "#F78700", fontWeight: 500 }}>
            Updated with Bowel Sounds
          </div>
          <Title>{appDetails.title}</Title>
          <BodyCopy>{appDetails.description}</BodyCopy>
          <StyledButton
            className="get"
            onClick={() => {
              mixpanel.track("Homepage", {
                App: appDetails.title,
                Action: "Clicked",
                "Click Action": "Get App",
              });
              window.location = appDetails.link;
            }}>
            Get App
          </StyledButton>
        </div>
        <AnchorTag
          className="desktop"
          href={appDetails.link}
          onClick={() => {
            mixpanel.track("Homepage", {
              App: appDetails.title,
              Action: "Clicked",
              "Click Action": "App Image",
            });
          }}>
          <img src={appDetails.imgSrcProp} alt={appDetails.imgAltProp} />
        </AnchorTag>

        <div className="mobile">
          <div className="mobile-photo-container">
            <div className="mobile-title">
              <div className="featured-label">Featured App</div>
              <Title>{appDetails.title}</Title>
            </div>
            <AnchorTag
              href={appDetails.link}
              onClick={() => {
                mixpanel.track("Homepage", {
                  App: appDetails.title,
                  Action: "Clicked",
                  "Click Action": "App Image",
                });
              }}>
              <img src={appDetails.imgSrcProp} alt={appDetails.imgAltProp} />
            </AnchorTag>
          </div>
          <div className="mobile-bottom-container">
            <BodyCopy className="mobile-description">
              {appDetails.description}
            </BodyCopy>
            <StyledButton
              className="get"
              onClick={() => {
                mixpanel.track("Homepage", {
                  App: appDetails.title,
                  Action: "Clicked",
                  "Click Action": "Get App",
                });
                window.location = appDetails.link;
              }}>
              Get App
            </StyledButton>
          </div>
        </div>
      </FeaturedAppContainer>

      <ScreenshotsContainer className="desktop">
        <BodyCopy>Screenshots</BodyCopy>
        <div className="screenshot-block">
          <img src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/screenshot-1.jpg" />
          <img src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/screenshot-2.jpg" />
          <img src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/screenshot-3.jpg" />
          <img src="https://s3-us-west-2.amazonaws.com/medzcool/auscultate/screenshot-4.jpg" />
        </div>
        <BodyCopy>
          <Link
            to="/auscultate"
            className="link"
            onClick={() => {
              mixpanel.track("Homepage", {
                App: appDetails.title,
                Action: "Clicked",
                "Click Action": "Learn More",
              });
            }}>
            Learn more about Auscultate
          </Link>
        </BodyCopy>
      </ScreenshotsContainer>
    </ContentBlock>
  );
}
const StyledButton = styled.button`
  font-family: ${fontFamily.sansSerif};
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  background: ${blue.bright};
  color: white;
  &.get {
    margin-top: 15px;
  }
  @media (max-width: 425px) {
    &.get {
      margin-top: 10px;
    }
  }
`;

const FeaturedAppContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 50px;
  font-family: ${fontFamily.sansSerif};
  padding: 25px;
  margin-top: 15px;
  .featured-label {
    font-weight: ${fontWeight.light};
    font-size: 16px;
  }
  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
  .mobile {
    display: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    padding: 5px 5px 15px 5px;
    margin: 15px 15px 0 15px;
    border-radius: 10px;
    border: 1px solid #f0f0f0;
    .mobile-photo-container {
      position: relative;
      .mobile-title {
        position: absolute;
        padding: 15px;
      }
    }
    .mobile-description {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .mobile-bottom-container {
    padding: 0 10px;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    img {
      height: 350px;
    }
  }
  @media (max-width: 768px) {
    grid-template-columns: 100%;
    padding: 0;
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
  @media (max-width: 425px) {
    img {
      height: 250px;
    }
    ${AnchorTag} {
      font-size: 12px;
    }
  }
`;

const ScreenshotsContainer = styled.div`
  max-width: 900px;
  margin: auto;
  margin-top: 35px;
  padding: 0 15px;
  .screenshot-block {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    img {
      width: 100%;
    }
  }
  .link {
    text-align: center;
    display: block;
    color: ${blue.bright};
    font-size: 1em;
    font-family: ${fontFamily.sansSerif};
    padding: 25px 15px 0 15px;
  }
  @media (max-width: 425px) {
    &.desktop {
      display: none;
    }
    .screenshot-block {
      grid-template-columns: 1fr 1fr;
      grid-gap: 8px;
    }
  }
`;
