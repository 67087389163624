import React, { useState, useEffect } from "react";

// Styles
import styled from "styled-components";
import { SmallTitle, fontFamily, MediumText } from "../Typography";

// Dependencies

// Styles
import { grayscale } from "../Utilities";

export default function AppCard(props) {
  const {
    title,
    logo,
    url,
    description,
    checkoutThumbnail,
    collectionTitle,
    collectionUrl,
    collection,
    mixpanel,
  } = props;

  const [redirectURL, setRedirectURL] = useState();
  useEffect(() => {
    // SET REDIRECT URL
    if (collection) {
      setRedirectURL(`https://rounds.medzcool.com/collection/` + collectionUrl);
    } else {
      setRedirectURL(url);
    }
  }, []);

  return (
    <AppCardContainer
      onClick={() => {
        mixpanel.track("Clicked App Card", {
          App: title,
          Collection: collectionTitle,
          Location: "Dashboard",
        });
        window.location = redirectURL;
      }}
    >
      <img src={logo || checkoutThumbnail} alt={title} />
      <SmallTitle>{title || collectionTitle}</SmallTitle>
      <MediumText>{description}</MediumText>

      <ButtonContainer>
        <div className="sub-container">
          <StyledButton>
            <div>{collection ? `GO TO COLLECTION` : `GO TO APP`}</div>
          </StyledButton>
        </div>
      </ButtonContainer>
    </AppCardContainer>
  );
}

const AppCardContainer = styled.div`
  padding: 25px;
  box-shadow: 2px 4px 9px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid #f2f2f2;
  max-width: 300px;
  position: relative;
  cursor: pointer;
  img {
    height: 75px;
    width: 75px;
    object-fit: cover;
    margin-bottom: 15px;
  }
  .button-container {
    margin-top: 40px;
  }
  @media (max-width: 768px) {
    max-width: none;
    width: auto;
  }
`;
const ButtonContainer = styled.div`
  margin-top: 40px;
  .sub-container {
    position: absolute;
    left: 6%;
    bottom: 10px;
    width: 88%;
  }
`;
const StyledButton = styled.button`
  font-family: ${fontFamily.sansSerif};
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 14px;
  color: white;
  border-radius: 5px;
  background: ${grayscale.darkDark};
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 3px;
  align-items: center;
  width: 100%;
`;
