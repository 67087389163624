import React, { useEffect } from "react";

// Styles
import styled from "styled-components";

export default function HorizontalResponsiveAd(props) {
  const { border } = props;

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <AdContainer border={border}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3581415225953957"
        data-ad-slot="8747186814"
        data-ad-format="horizontal"
        data-full-width-responsive="true"
      />
    </AdContainer>
  );
}

const AdContainer = styled.div`
  padding: 20px 15px;
  margin: 20px 0;
  text-align: center;
  ${props =>
    props.border &&
    `border-top: 1px solid rgba(0, 0, 0, 0.1); border-bottom: 1px solid rgba(0, 0, 0, 0.1);`}
`;
