import React from "react";

// Styles
import styled from "styled-components";
import { Logo } from "../Typography";
import { themeColors, fontFamily } from "../Utilities";

// Static
import mobileLogo from "../header-mobile-logo.svg";

export function ServerError(props) {
  return (
    <>
      <HeaderContainer>
        <HeaderInnerContainer>
          <HeaderLeft>
            <Logo>
              <a href="/">
                <img src={mobileLogo} className="logo-icon" />
              </a>

              <span className="logo-text">
                <a href="/">MEDZCOOL</a>
              </span>
            </Logo>
          </HeaderLeft>
        </HeaderInnerContainer>
      </HeaderContainer>
      <ErrorContainer>
        <h1>Oops, something is wrong!</h1>
        <p>
          If you are receiving this message, our servers might be down. Kindly reach out to notify us of the problem at:{" "}
          <a href="mailto:hello@medzcool.com">hello@medzcool.com</a>
        </p>
      </ErrorContainer>
    </>
  );
}

const ErrorContainer = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 100px 25px 50px 25px;
  text-align: center;
  font-family: ${fontFamily.sansSerif};
`;
const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 50px;
  background-color: ${themeColors.dark};
`;
const HeaderInnerContainer = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  max-width: 1300px;
  margin: auto;
`;
const HeaderLeft = styled.div`
  color: white;
  margin-left: 15px;
  .logo-icon {
    height: 30px;
    display: none;
  }
  .logo-text {
    a {
      text-decoration: none;
      color: white;
    }
  }
  .menu-item {
    font-family: ${fontFamily.sansSerif};
    cursor: pointer;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .mobile {
    display: none;
  }
  @media (max-width: 768px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
  @media (max-width: 425px) {
    .logo-icon {
      display: block;
    }
    .logo-text {
      display: none;
    }
  }
`;
