import React, { Fragment } from "react";

// Settings

// Styles
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { Title, SmallTitle, BodyCopy, fontFamily } from "../Typography";

// Apollo, GraphQL
import { useQuery, gql } from "@apollo/client";

const GET_FREE_ENCOUNTERS = gql`
  query getCollection($collectionUrl: String!) {
    getCollection(collectionUrl: $collectionUrl) {
      id
      encounterSet {
        id
        hashId
        title
        description
      }
    }
  }
`;

export default function MoreClinicalCases(props) {
  const { mixpanel } = props;

  const { loading, error, data } = useQuery(GET_FREE_ENCOUNTERS, {
    variables: { collectionUrl: "homepage-free" },
  });
  if (loading) return null;
  if (error) return null;

  const encounters = data.getCollection.encounterSet;

  return (
    <CasesContainer>
      <Title>
        Free
        <br />
        Clinical Cases
      </Title>
      <div>
        {encounters.map((encounter) => {
          return (
            <a
              key={encounter.id}
              className="case"
              href={`https://rounds.medzcool.com/case/${encounter.hashId}`}
              onClick={() => {
                mixpanel.track("Homepage", {
                  Category: "Encounter",
                  EncounterID: encounter.hashId,
                  Action: "Clicked",
                  "Click Action": "Free Encounter",
                });
              }}>
              <SmallTitle>{encounter.title}</SmallTitle>
              <BodyCopy>{encounter.description}</BodyCopy>
              <BodyCopy className="answer-prompt">Answer Case</BodyCopy>
            </a>
          );
        })}
      </div>
    </CasesContainer>
  );
}
const CasesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 100px;
  margin-top: 120px;
  margin-bottom: 100px;
  ${Title} {
    font-weight: 500;
  }
  .case {
    display: block;
    text-decoration: none;
    color: black;
    margin-bottom: 50px;
    font-family: ${fontFamily.sansSerif};
    max-width: 500px;
    .answer-prompt {
      color: #295aec;
      margin-top: 5px;
    }
  }
  @media (max-width: 1200px) {
    grid-template-columns: 1fr 2fr;
  }
  @media (max-width: 768px) {
    .case {
      margin-bottom: 35px;
    }
    grid-template-columns: 1fr;
    grid-gap: 18px;
    margin-top: 100px;
  }
`;
