import React from "react";

// Dependencies

//Components
import Spinner from "../Utilities/Spinner";

// Styles
import styled from "styled-components";
import { LargeText } from "../Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";

// Apollo, GraphQL
// import gql from "graphql-tag";
import { useQuery, gql } from "@apollo/client";

const GET_COLLECTIONS = gql`
  query GET_COLLECTIONS {
    collections {
      id
      collectionUrl
      collectionTitle
      codehealthCollectionId
      description
      thumbnail
      checkoutThumbnail
      caseCount
      price
      salePrice
      isSale
      isLive
    }
  }
`;

export default function CollectionDashboard(props) {
  const { AppCard, mixpanel } = props;

  const { loading, error, data } = useQuery(GET_COLLECTIONS);

  if (loading) return <Spinner />;

  if (props.user.profile.subscriptionStatus == "active") {
    return (
      <div className="subscription-grid">
        {data.collections.map((collection) => {
          if (collection.isLive) {
            console.log(collection.thumbnail);
            return (
              <AppCard
                key={collection.id}
                {...collection}
                subscribed
                collection
                logo={collection.thumbnail}
                mixpanel={mixpanel}
              />
            );
          }
        })}
      </div>
    );
  }

  if (props.user.profile.subscribedRounds.length > 0) {
    return (
      <>
        <p style={{ marginTop: 5, marginBottom: 35 }}>
          Explore <a href="https://rounds.medzcool.com">more collections.</a>
        </p>
        <div className="subscription-grid">
          {props.user.profile.subscribedRounds.map((collection) => {
            console.log(collection);
            return (
              <AppCard
                key={collection.id}
                {...collection}
                logo={collection.thumbnail}
                subscribed
                collection
              />
            );
          })}
        </div>
      </>
    );
  } else {
    return (
      <div className="no-subscriptions">
        <LargeText>
          <p>You have not subscribed to any collections.</p>
          <a href="https://rounds.medzcool.com">
            Learn more about collections <Chevron icon={faChevronRight} />
          </a>
        </LargeText>
      </div>
    );
  }

  // function renderCollections() {
  //   if (props.user.profile.subscriptionStatus == "active") {
  //     return (
  //       <div className="subscription-grid">
  //         <Query query={GET_COLLECTIONS}>
  //           {({ loading, data }) => {
  //             if (loading) return <Spinner />;
  //             return data.collections.map((collection) => {
  //               if (collection.isLive) {
  //                 return (
  //                   <AppCard
  //                     key={collection.id}
  //                     {...collection}
  //                     subscribed
  //                     collection
  //                   />
  //                 );
  //               }
  //             });
  //           }}
  //         </Query>
  //       </div>
  //     );
  //   } else {
  //     if (props.user.profile.subscribedRounds.length > 0) {
  //       return (
  //         <>
  //           <p style={{ marginTop: 5, marginBottom: 35 }}>
  //             Explore{" "}
  //             <a href="https://rounds.medzcool.com">more collections.</a>
  //           </p>
  //           <div className="subscription-grid">
  //             {props.user.profile.subscribedRounds.map((app) => {
  //               return <AppCard key={app.id} {...app} subscribed collection />;
  //             })}
  //           </div>
  //         </>
  //       );
  //     } else {
  //       return (
  //         <div className="no-subscriptions">
  //           <LargeText>
  //             <p>You have not subscribed to any collections.</p>
  //             <a href="https://rounds.medzcool.com">
  //               Learn more about collections <Chevron icon={faChevronRight} />
  //             </a>
  //           </LargeText>
  //         </div>
  //       );
  //     }
  //   }
  // }
  // return renderCollections();
}

const Chevron = styled(FontAwesomeIcon)`
  vertical-align: -3px;
  margin-left: 6px;
`;
